import React, { useState } from "react";
import { IcChecked, IcCopyFlat, IcTickmark } from "@/assets";
import toast from "react-hot-toast";
import IcCheckmark from "@/assets/ic_checkmark";

interface BankDetailProps {
  data: {
    title: string;
    value: string;
    canCopy?: boolean;
  }[];
}

const BankDetail = ({ data }: BankDetailProps) => {
  const [copiedIndex, setCopiedIndex] = useState<number | null>(null);

  const copyText = (text: string, index: number) => () => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied to Clipboard");
      setCopiedIndex(index);

      // Revert back to the Copy icon after a short delay (e.g., 2 seconds)
      setTimeout(() => setCopiedIndex(null), 2000);
    });
  };

  return (
    <div className="grid grid-cols-2 w-full p-4">
      {data.map((item, index) => (
        <div key={index} className="flex flex-col mb-4">
          <p className="text-[10px] font-normal leading-[15px] text-monochrome-20 mb-1">{item.title}</p>
          <div className="flex items-center gap-2">
            <p className="text-xs font-medium text-white leading-[18px]">{item.value}</p>
            {item.canCopy && (
              <button className="cursor-pointer" onClick={copyText(item.value, index)}>
                {copiedIndex === index ? <IcCheckmark className="text-green-500" /> : <IcCopyFlat />}
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BankDetail;
