import React from 'react'

const IcArrowNext = () => {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M6.57153 16.1251L13.5833 9.11325L14.7618 10.2917L7.75004 17.3036L6.57153 16.1251Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M7.75004 3.27991L14.7618 10.2917L13.5833 11.4702L6.57153 4.45841L7.75004 3.27991Z" fill="white" />
        </svg>

    )
}

export default IcArrowNext;