import React, { useContext, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import Web3 from "web3";
import { IcDropdownChecked } from "@/assets/menu";
import { AuthContext } from "@/provider/AuthContext";
import { Modal } from "flowbite-react";
import Button from "@/components/UI/Button";
import IcCross from "@/assets/ic_cross";
import Loader from "@/components/Loader";

interface WithdrawWalletModalProps {
  show: boolean;
  handleClose: () => void;
}

const WithdrawWallet = ({ show, handleClose }: WithdrawWalletModalProps) => {
  const { provider } = useContext(AuthContext);
  const [amount, setAmount] = useState<string>("");
  const [destinationAddress, setDestinationAddress] = useState<string>("");
  const [balance, setBalance] = useState<string>("");
  const [transactionHash, setTransactionHash] = useState<string>("");
  const [isTransferring, setIsTransferring] = useState<boolean>(false);

  const PENOMO_ADDRESS = "0xF1B3f692a9faB2703637F293Bc2c34a87B532ae4";
  const PENOMO_ABI = useMemo(
    () => [
      {
        inputs: [
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "transfer",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "balanceOf",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "decimals",
        outputs: [
          {
            internalType: "uint8",
            name: "",
            type: "uint8",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "name",
        outputs: [
          {
            internalType: "string",
            name: "",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "symbol",
        outputs: [
          {
            internalType: "string",
            name: "",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
    ],
    []
  );

  useEffect(() => {
    if (provider) {
      const web3 = new Web3(provider);
      const fetchBalances = async () => {
        try {
          const accounts = await web3.eth.getAccounts();
          if (accounts.length === 0) return;

          const tokenContract = new web3.eth.Contract(PENOMO_ABI as any, PENOMO_ADDRESS);
          const tokenBalance = await tokenContract.methods.balanceOf(accounts[0]).call();
          const balance = web3.utils.fromWei(tokenBalance, "ether");

          setBalance(balance);
        } catch (error) {
          console.error("Error fetching balance:", error);
          toast.error("Error Fetching Balance");
        }
      };

      fetchBalances();
    }
  }, [provider, PENOMO_ABI]);

  const handleTransfer = async () => {
    if (!provider) return;
    setIsTransferring(true);

    const web3 = new Web3(provider);
    try {
      const accounts = await web3.eth.getAccounts();

      const tokenContract = new web3.eth.Contract(PENOMO_ABI as any, PENOMO_ADDRESS);
      const estimatedGas = await tokenContract.methods
        .transfer(destinationAddress, web3.utils.toWei(amount, "ether"))
        .estimateGas({ from: accounts[0] });
      const txReceipt = await tokenContract.methods
        .transfer(destinationAddress, web3.utils.toWei(amount, "ether"))
        .send({
          from: accounts[0],
          gasPrice: await web3.eth.getGasPrice(),
          gas: estimatedGas,
        });

      setTransactionHash(txReceipt.transactionHash);
      toast.success("Transaction successful. You can check it out on the explorer.");
    } catch (error: any) {
      console.error("Transaction error:", error);
      toast.error("Transaction failed: " + error.message);
    } finally {
      setIsTransferring(false);
    }
  };

  const explorerLink = `${process.env.REACT_APP_ETHERSCAN_TRANSACTION_URL}${transactionHash}`;

  return (
    <Modal show={show} onClose={!isTransferring ? handleClose : undefined} position={"center"} size={"2xl"}>
      <Modal.Body className={`p-4 bg-monochrome-100 rounded ${isTransferring ? "pointer-events-none" : ""}`}>
        {
          transactionHash == '' && <>
            <div className={"flex justify-between items-center pb-5"}>
              <h1 className="mb-0">Withdraw PENOMO Tokens</h1>
              <Button classNames="w-[32px] bg-monochrome-5 border border-monochrome-10 !px-2 !rounded-xl" rounded onClick={!isTransferring ? handleClose : undefined}>
                <IcCross />
              </Button>
            </div>
            <hr />
          </>
        }
        <section className="p-4 flex flex-col gap-7">

          {transactionHash == '' && <p className="mb-3">PENOMO Balance: {balance}</p>} 
          {!transactionHash && (
            <div className="flex flex-col gap-3">
              <input
                type="text"
                name="amount"
                placeholder="Amount (PENOMO)"
                disabled={isTransferring}
                className={`w-full px-0 border border-monochrome-10 rounded px-2`}
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <input
                type="text"
                name="destinationAddress"
                placeholder="Destination Address"
                disabled={isTransferring}
                className={`w-full px-0 border border-monochrome-10 rounded px-2`}
                value={destinationAddress}
                onChange={(e) => setDestinationAddress(e.target.value)}
              />
              <Button
                primary
                rounded
                classNames="h-[40px]"
                onClick={handleTransfer}
                disabled={!amount || !destinationAddress || parseFloat(amount) > parseFloat(balance) || isTransferring}
              >
                {isTransferring ? (
                  <>
                    <Loader isLoading={true} />
                  </>
                ) : (
                  "Transfer"
                )}
              </Button>
            </div>
          )}
          {transactionHash && (
            <section className="p-4 flex flex-col gap-7 items-center">
              <IcDropdownChecked className={"h-10 w-10 bg-status-approved rounded-full p-2"} />
              <div className="flex flex-col gap-2 items-center">
                <h3>Withdrawal Successful</h3>
                <p className="text-sm w-[90%] text-center">Your transaction was successful. You can view the transaction details on the block explorer.</p>
              </div>
              <Button primary rounded onClick={() => window.open(explorerLink, "_blank")} classNames="w-full">
                View Transaction
              </Button>
              <Button primary rounded onClick={handleClose} classNames="w-full">
                Close
              </Button>
            </section>
          )}
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default WithdrawWallet;
