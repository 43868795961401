import { useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";

const useSocketIO = (userId: string, onMessage: (data: any) => void) => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const socketioServer = process.env.REACT_APP_SOCKETIO_CONNECTION;

  useEffect(() => {
    if (!userId) return;

    // Create socket connection with userId in query
    const newSocket = io(socketioServer, {
      query: { userId },
    });

    newSocket.on("connect", () => {
      // console.log("Socket connected for user:", userId);
    });

    newSocket.on("kycStatusUpdate", (data) => {
      // Ensure the event is for the specific user
      if (data.userId === userId) {
        // console.log("Received kycStatusUpdate: ", data);
        onMessage(data);
      }
    });

    newSocket.on("documentAlreadyUsed", (data) => {
      // Ensure the event is for the specific user
      if (data.userId === userId) {
        // console.log("Received documentAlreadyUsed: ", data);
        onMessage(data);
      }
    });

    newSocket.on("connect_error", (error) => {
      console.error("Socket connection error:", error);
    });

    setSocket(newSocket);

    // Cleanup on component unmount
    return () => {
      if (newSocket) {
        newSocket.disconnect();
      }
    };
    // eslint-disable-next-line
  }, [userId, socketioServer]);

  return socket;
};

export default useSocketIO;