import React from "react";

const FillGovernance = () => {
  return (
    <div>
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.1666 8.99997H12.8986L14.6306 5.99997C14.7632 5.77029 14.7992 5.49735 14.7305 5.24117C14.6619 4.985 14.4943 4.76658 14.2646 4.63397L11.6666 3.13397C11.4369 3.00137 11.164 2.96544 10.9078 3.03407C10.6517 3.10271 10.4332 3.2703 10.3006 3.49997L7.80063 7.82997C7.69485 8.01296 7.64977 8.22477 7.67187 8.43497C7.69397 8.64518 7.78211 8.84298 7.92363 8.99997H7.16663C7.03402 8.99997 6.90684 9.05265 6.81307 9.14642C6.7193 9.24019 6.66663 9.36736 6.66663 9.49997C6.66663 9.63258 6.7193 9.75976 6.81307 9.85353C6.90684 9.94729 7.03402 9.99997 7.16663 9.99997H14.1666C14.2992 9.99997 14.4264 9.94729 14.5202 9.85353C14.6139 9.75976 14.6666 9.63258 14.6666 9.49997C14.6666 9.36736 14.6139 9.24019 14.5202 9.14642C14.4264 9.05265 14.2992 8.99997 14.1666 8.99997ZM11.1666 3.99997L13.7646 5.49997L11.7446 8.99997H9.82663L8.66663 8.32997L11.1666 3.99997ZM15.2036 6.99997L14.6266 7.99997H15.1666L17.4166 11H3.91663L6.16663 7.99997H6.57263C6.59263 7.94197 6.61863 7.88531 6.65063 7.82997L7.13063 6.99997H6.16663C6.01138 6.99997 5.85827 7.03612 5.71941 7.10555C5.58056 7.17497 5.45977 7.27578 5.36663 7.39997L2.86663 10.733C2.7368 10.9061 2.66663 11.1166 2.66663 11.333V16C2.66663 16.2652 2.77198 16.5195 2.95952 16.7071C3.14706 16.8946 3.40141 17 3.66663 17H17.6666C17.9318 17 18.1862 16.8946 18.3737 16.7071C18.5613 16.5195 18.6666 16.2652 18.6666 16V11.333C18.6666 11.1166 18.5964 10.9061 18.4666 10.733L15.9666 7.39997C15.8773 7.28065 15.7624 7.1828 15.6304 7.1136C15.4984 7.04439 15.3526 7.00557 15.2036 6.99997Z"
          fill="#00CC9C"
        />
      </svg>
    </div>
  );
};

export default FillGovernance;
