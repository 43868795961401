import React, { useContext, useEffect, useState } from "react";
import Web3 from "web3";
import { AuthContext } from "@/provider/AuthContext";
import Loader from "./Loader";
import Button from "./UI/Button";
import { IcTopUp, IcWithdrow } from "@/assets";
import WithdrawWallet from "./Page/Dashboard/Dialogs/WithdrawWallet";
import { useQuery } from "@tanstack/react-query";
import { getQuestStatistics } from "@/services/api";
import { useNavigate } from "react-router-dom";

const PenomoFlipCards = () => {
  const navigate = useNavigate()
  const { provider } = useContext(AuthContext);
  const [balance, setBalance] = useState<string>("0.00");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);

  const {
    data: questStatistics,
    isLoading: isLoadingQuest,
    refetch: refetchStatistics,
  } = useQuery({
    queryKey: ["getQuestStatistics"],
    queryFn: () => getQuestStatistics(),
    select: (data: any) => data.data,
  });

  useEffect(() => {
    if (!provider) {
      console.error("Web3 provider is not available");
      setIsLoading(false);
      return;
    }

    const web3 = new Web3(provider);

    const tokenAddress = "0xF1B3f692a9faB2703637F293Bc2c34a87B532ae4";
    const tokenABI = [
      {
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        stateMutability: "view",
        type: "function",
      },
    ];

    const tokenContract = new web3.eth.Contract(tokenABI as any, tokenAddress);

    const fetchTokenBalance = async () => {
      try {
        const accounts = await web3.eth.getAccounts();
        if (accounts.length === 0) {
          console.error("No account found.");
          setIsLoading(false);
          return;
        }

        const walletAddress = accounts[0];
        const balanceInWei = await tokenContract.methods.balanceOf(walletAddress).call();
        const balanceInPenomo = web3.utils.fromWei(balanceInWei, "ether");
        setBalance(balanceInPenomo);
      } catch (error) {
        console.error("Error fetching token balance:", error);
      } finally {
        setIsLoading(false);
      }
    };
  }, [provider]);

  return (
    <>
      <div className="bg-monochrome-100 p-4 flex flex-col justify-between rounded-lg">
        <div className="flex flex-row gap-4 items-start">
          <img src="./assets/ic_penomo_rewards.svg" alt="Penomo Rewards" />
          <div className="w-full flex flex-col gap-2">
            <p className="text-[#9E9E9E] text-[0.688rem]">PNMO Rewards</p>
            {isLoading ? (
              <Loader isLoading={isLoading} />
            ) : (
              <div className="w-full grid grid-cols-2 gap-4 mt-4">
                <div className="flex justify-center items-baseline mr-20">
                  <span className="font-medium text-[2rem]">0</span>
                  <sub className="text-monochrome-20 text-base ml-1">PNMO</sub>
                </div>
                <div className="flex justify-center items-baseline mr-6">
                  <span className="font-medium text-[2rem]">{questStatistics?.claimedPoint || 0}</span>
                  <sub className="text-monochrome-20 text-base ml-1">XP</sub>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2 mt-4 gap-4">
          <Button primary rounded classNames="!text-[0.75rem] font-normal" onClick={() => navigate("/campaigns")}>
            <IcTopUp />
            Earn
          </Button>
          <Button primary rounded disabled classNames="!text-[0.75rem] font-normal" onClick={() => setShowWithdrawModal(true)}>
            <IcWithdrow />
            Claim
          </Button>
        </div>
      </div>
      {showWithdrawModal && <WithdrawWallet show={showWithdrawModal} handleClose={() => setShowWithdrawModal(false)} />}
    </>
  );
};

export default PenomoFlipCards;
