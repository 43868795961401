import { Container, Row, Col } from "react-bootstrap";
import "./InvestmentCard.css";
import iconWallet1 from "../../../../../assets/iconWallet1.svg";
import iconWallet2 from "../../../../../assets/iconWallet2.svg";
import iconWallet3 from "../../../../../assets/iconWallet3.svg";
import WorldFlag from "react-world-flags";
import lookup from "country-code-lookup";
import { IcGrowth, IcLabel, IcPercentageWithBackground } from "@/assets";
import { IcPercentage } from "@/assets/menu";
import Button from "../../../../../../src/components/UI/Button";

interface InvestmentCardProps {
  projectData?: {
    name?: string;
    fundingGoal?: number;
    tokenPrice?: number;
    irr?: number;
    assetLocation?: string;
    revenueStreams?: Array<{ name: string }>;
    assetIds?: Array<{ assetType?: string }>;
  };
}

const solar = "/assets/dummy/solar.jpg";
const wind = "/assets/dummy/wind.jpg";
const battery = "/assets/dummy/battery.jpg";
const ev = "/assets/dummy/ev.jpg";
const other = "/assets/dummy/other.png";

const assetImages: { [key: string]: string } = {
  SOLAR: solar,
  WIND: wind,
  BATTERY: battery,
  EVCHARGEPOINT: ev,
  OTHER: other,
};

const InvestmentCard = ({ projectData = {} }: any) => {
  // console.log("Project Data in Investment Card: ", projectData);

  const { name = "", fundingGoal = 0, tokenPrice = 0, irr = 0, assetLocation = "", revenueStreams = [], assetIds = [] } = projectData;

  const getCountryCode = (countryName: string) => {
    const country = lookup.byCountry(countryName);
    return country?.iso2;
  };

  const assetType = projectData.assetIds?.length > 0 ? projectData.assetIds[0]?.assetType : "OTHER";
  const imageSrc = projectData.projectImage ? projectData.projectImage : assetImages[assetType] || other;

  return (
    <div className="investment-card relative">
      <img src={imageSrc} className="h-full w-full object-cover absolute opacity-30" />
      <div className="p-4">
        {/* <div className="z-10 relative header tab-container w-auto lg:w-1/3 flex flex-wrap" style={{ gap: "10px" }}>
          {revenueStreams.length > 0 ? (
            revenueStreams.map((item: any, index: any) => (
              <div key={index} className="tab mx-0">
                {item.name}
              </div>
            ))
          ) : (
            <></>
            // <Col className="tab">No Revenue Streams</Col>
          )}
        </div> */}
        <div className="z-10 relative grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
          <div>
            <div className="title">{name}</div>
            <div className="flex items-center gap-10">
              <div className="subtitle text-gray">{assetIds.length > 0 ? assetIds[0]?.assetType : "N/A"}</div>
              <div className="w-1 h-1 bg-monochrome-20 rounded-full"></div>
              <div className="country text-gray flex items-center" style={{ gap: "10px" }}>
                <WorldFlag code={getCountryCode(assetLocation)} style={{ width: "26px", height: "26px" }} />
                <span>{assetLocation}</span>
              </div>
            </div>
            {/* <Button variant="success" className="invest-button">
            Invest Now
          </Button> */}
          </div>
          <div>
            <div className="info">
              <div className="flex" style={{ gap: "10px" }}>
                <img style={{ width: "26px", height: "26px" }} src={iconWallet3} alt="iconWallet3" />
                <div className="info-item">
                  <span className="info-label text-gray flex">Token Price</span>
                  <span className="info-value">${tokenPrice?.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) || "N/A"}</span>
                </div>
              </div>
              <div className="flex" style={{ gap: "10px" }}>
                <img style={{ width: "26px", height: "26px" }} src={iconWallet2} alt="iconWallet2" />
                <div className="info-item">
                  <span className="info-label text-gray flex">Total Volume</span>
                  <span className="info-value">${fundingGoal?.toLocaleString(navigator.language, { minimumFractionDigits: 0 }) || "N/A"}</span>
                </div>
              </div>
              <div className="flex" style={{ gap: "10px" }}>
                <img style={{ width: "26px", height: "26px" }} src={iconWallet1} alt="iconWallet1" />
                <div className="info-item">
                  <span className="info-label text-gray flex">APY</span>
                  <span className="info-value">{10}%</span>
                </div>
              </div>
            </div>
          </div>
          <Col></Col>
        </div>
      </div>
    </div>
  );
};

export default InvestmentCard;
