// import React, { useState, useEffect } from "react";
// import { useQuery } from "@tanstack/react-query";
// import useStore from "@store/useStore";
// import { fetchAllProjects } from "@services/api";
// import Loader from "@/components/Loader";
// import "./Marketplace.css";
// import ProjectCardItem from "@/components/Page/Marketplace/ProjectCardItem";
// import RwaPoolsCardItem from "@/components/Page/Marketplace/RwaPoolsCardItem";
// import ProjectView from "./Projects/ProjectView";
// import Tabs from "@/components/UI/Tab";
// import { useNavigate } from "react-router-dom";
// import { Modal } from "flowbite-react";
// import Button from "@/components/UI/Button";
// import IcCross from "@/assets/ic_cross";
// import { IcWarning } from "@/assets";

// const Marketplace = () => {
//   const navigate = useNavigate();
//   const [selectedProject, setSelectedProject] = useState(null);
//   const [showErrorModal, setShowErrorModal] = useState(false);
//   const [errorModalContent, setErrorModalContent] = useState({
//     title: "Error",
//     message: "",
//   });
//   const { investorData: investorDetails } = useStore();
//   const [projectsData, setProjectsData] = useState<any[]>([]); // Store fetched data
//   const [activeTab, setActiveTab] = useState<number>(0);
//   const tabs = ["Projects", "RWA Pools"];

//   const { data: projects = [], isLoading } = useQuery({
//     queryKey: ["getProjects"],
//     queryFn: fetchAllProjects,
//     select: (data) => data.data?.filter((item: any) => item?.status === "Listed") ?? [],
//     enabled: activeTab === 0,
//   });

//   const filteredProjects = projects.filter((project: any) => {
//     if (project?.issuanceType === "public") {
//       return true;
//     }
//     if (project.issuanceType === "private") {
//       return project?.allowedInvestors.some((allowed: any) => allowed.investorIds.includes(investorDetails._id));
//     }
//     return false;
//   });

//   // const dummyRWAData = [
//   //   {
//   //     _id: "1",
//   //     assetIds: [{ assetType: "SOLAR" }],
//   //     name: "Solar Energy Project",
//   //     isOpen: true,
//   //     fundingReceived: 5000000,
//   //     totalFunding: 10000000,
//   //     progress: 50,
//   //     contractTerm: 12, // 12 months = 1 year
//   //     irr: 7.5,
//   //     poolSize: 20000000,
//   //   },
//   //   {
//   //     _id: "2",
//   //     assetIds: [{ assetType: "WIND" }],
//   //     name: "Wind Farm Expansion",
//   //     isOpen: false,
//   //     fundingReceived: 3000000,
//   //     totalFunding: 3000000,
//   //     progress: 100,
//   //     contractTerm: 18, // 18 months = 1.5 years
//   //     irr: 8.3,
//   //     poolSize: 15000000,
//   //   },
//   //   {
//   //     _id: "3",
//   //     assetIds: [{ assetType: "BATTERY" }, { assetType: "SOLAR" }],
//   //     name: "Hybrid Energy Storage",
//   //     isOpen: true,
//   //     fundingReceived: 750000,
//   //     totalFunding: 25000000,
//   //     progress: 30,
//   //     contractTerm: 24, // 24 months = 2 years
//   //     irr: 9.0,
//   //     poolSize: 30000000,
//   //   },
//   //   {
//   //     _id: "4",
//   //     assetIds: [{ assetType: "EVCHARGEPOINT" }],
//   //     name: "EV Charging Network",
//   //     isOpen: true,
//   //     fundingReceived: 200000,
//   //     totalFunding: 5000000,
//   //     progress: 40,
//   //     contractTerm: 6, // 6 months
//   //     irr: 6.8,
//   //     poolSize: 8000000,
//   //   },
//   //   {
//   //     _id: "5",
//   //     assetIds: [{ assetType: "OTHER" }],
//   //     name: "Miscellaneous Energy Project",
//   //     isOpen: false,
//   //     fundingReceived: 10000000,
//   //     totalFunding: 20000000,
//   //     progress: 70,
//   //     contractTerm: 36, // 36 months = 3 years
//   //     irr: 10.5,
//   //     poolSize: 50000000,
//   //   },
//   // ]; // Dummy data for tab 1

//   // const handlePoolClick = (pool: any) => {
//   //   console.log("Pool clicked", pool);
//   // };

//   const handleProjectClick = (project: any) => {
//     if (project.issuanceType === "public") {
//       const allowedTypes = project?.allowedInvestorType.split(", ");
//       if (allowedTypes.includes("Retail")) {
//         setSelectedProject(project);
//         navigate(`/marketplace/details?projectId=${project._id}`);
//       } else if (allowedTypes.includes(investorDetails?.investorType)) {
//         setSelectedProject(project);
//         navigate(`/marketplace/details?projectId=${project._id}`);
//       } else {
//         setErrorModalContent({
//           title: "Access Denied",
//           message: `Only ${allowedTypes.join(", ")} investors can view this project.`,
//         });
//         setShowErrorModal(true);
//       }

//       // if (project.allowedInvestorType === "Retail") {
//       //   if (["Retail", "Accredited", "Institutional"].includes(investorDetails?.investorType)) {
//       //     setSelectedProject(project);
//       //     navigate(`/marketplace/details?projectId=${project._id}`);
//       //   } else {
//       //     setErrorModalContent({
//       //       title: "Access Denied",
//       //       message: "Only Retail, Accredited, or Institutional investors can view this project.",
//       //     });
//       //     setShowErrorModal(true);
//       //   }
//       // } else if (project.allowedInvestorType === "Accredited") {
//       //   if (["Accredited", "Institutional"].includes(investorDetails?.investorType)) {
//       //     setSelectedProject(project);
//       //     navigate(`/marketplace/details?projectId=${project._id}`);
//       //   } else if (investorDetails?.investorType === "Retail") {
//       //     setErrorModalContent({
//       //       title: "Access Denied",
//       //       message: "Only Accredited and Institutional investors can view this project.",
//       //     });
//       //     setShowErrorModal(true);
//       //   } else {
//       //     setErrorModalContent({
//       //       title: "Access Denied",
//       //       message: `Only ${project?.allowedInvestorType} investors are allowed to view this project.`,
//       //     });
//       //     setShowErrorModal(true);
//       //   }
//       // } else {
//       //   if (allowedTypes.includes(investorDetails?.investorType)) {
//       //     setSelectedProject(project);
//       //     navigate(`/marketplace/details?projectId=${project._id}`);
//       //   } else {
//       //     setErrorModalContent({
//       //       title: "Access Denied",
//       //       message: `Only ${project?.allowedInvestorType} investors are allowed to view this project.`,
//       //     });
//       //     setShowErrorModal(true);
//       //   }
//       // }
//     } else {
//       setSelectedProject(project);
//       navigate(`/marketplace/details?projectId=${project?._id}`);
//     }
//   };

//   const dummyRWAData = [
//     {
//       _id: "1",
//       assetIds: [{ assetType: "SOLAR" }],
//       name: "Solar Energy Project",
//       isOpen: true,
//       fundingReceived: 5000000,
//       totalFunding: 10000000,
//       progress: 50,
//       contractTerm: 12, // 12 months = 1 year
//       irr: 7.5,
//       poolSize: 20000000,
//     },
//     {
//       _id: "2",
//       assetIds: [{ assetType: "WIND" }],
//       name: "Wind Farm Expansion",
//       isOpen: false,
//       fundingReceived: 3000000,
//       totalFunding: 3000000,
//       progress: 100,
//       contractTerm: 18, // 18 months = 1.5 years
//       irr: 8.3,
//       poolSize: 15000000,
//     },
//     {
//       _id: "3",
//       assetIds: [{ assetType: "BATTERY" }, { assetType: "SOLAR" }],
//       name: "Hybrid Energy Storage",
//       isOpen: true,
//       fundingReceived: 750000,
//       totalFunding: 25000000,
//       progress: 30,
//       contractTerm: 24, // 24 months = 2 years
//       irr: 9.0,
//       poolSize: 30000000,
//     },
//     {
//       _id: "4",
//       assetIds: [{ assetType: "EVCHARGEPOINT" }],
//       name: "EV Charging Network",
//       isOpen: true,
//       fundingReceived: 200000,
//       totalFunding: 5000000,
//       progress: 40,
//       contractTerm: 6, // 6 months
//       irr: 6.8,
//       poolSize: 8000000,
//     },
//     {
//       _id: "5",
//       assetIds: [{ assetType: "OTHER" }],
//       name: "Miscellaneous Energy Project",
//       isOpen: false,
//       fundingReceived: 10000000,
//       totalFunding: 20000000,
//       progress: 70,
//       contractTerm: 36, // 36 months = 3 years
//       irr: 10.5,
//       poolSize: 50000000,
//     },
//   ]; // Dummy data for tab 1

//   const handlePoolClick = (pool: any) => {
//     console.log("Pool clicked", pool);
//   };

//   const handleCloseModal = () => {
//     setSelectedProject(null);
//   };

//   useEffect(() => {
//     if (activeTab === 0) {
//       setProjectsData(dummyRWAData);
//     }
//   }, [activeTab]);

//   return (
//     <>
//       <div className="-mt-2">
//       <Tabs tabs={tabs} activeTab={activeTab} onClick={setActiveTab} className="mb-4" />

// {isLoading && (
//   <div className="w-full flex justify-center items-center h-20">
//     <Loader isLoading={true} />
//   </div>
// )}

// {selectedProject && <ProjectView project={selectedProject} close={handleCloseModal} />}

// {/* Error Modal */}
// <Modal show={showErrorModal} onClose={() => setShowErrorModal(false)} position="center" size="xl">
//   <Modal.Body className="p-4 bg-monochrome-100">
//     <div className="flex justify-between items-center pb-5">
//       <h3 className="mb-0">{errorModalContent.title}</h3>
//       <Button classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" onClick={() => setShowErrorModal(false)}>
//         <IcCross />
//       </Button>
//     </div>
//     <hr className="border-monochrome-40" />
//     <div className="my-6 flex flex-col justify-center items-center text-center space-y-6">
//       <IcWarning />
//       <p className="text-white text-sm mt-2">{errorModalContent.message}</p>
//     </div>
//     <div className="flex justify-center mt-8">
//       <Button classNames="btn btn-primary w-full" onClick={() => setShowErrorModal(false)}>
//         OK
//       </Button>
//     </div>
//   </Modal.Body>
// </Modal>
// {!isLoading && investorDetails?.investorType !== "Retail" && projects.length === 0 && (
//   <div className="text-center w-full mt-4">Currently no projects are listed. Stay tuned.</div>
// )}

// {activeTab === 0 && (
//   <>
//     <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4">
//       {filteredProjects.map((project: any, index: number) => (
//         <ProjectCardItem key={"project-" + index} item={project} onClick={() => handleProjectClick(project)} />
//       ))}
//     </div>
//     {selectedProject && <ProjectView project={selectedProject} close={handleCloseModal} />}
//   </>
// )}

// {activeTab === 1 && (
//   <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-4">
//     {dummyRWAData.map((pool: any, index: number) => (
//       <RwaPoolsCardItem key={"rwa-" + index} item={pool} onClick={() => handlePoolClick(pool)} />
//     ))}
//   </div>
// )}

//       </div>
//     </>
//   );
// };

// export default Marketplace;


import React from "react";

const MarketplacePage = () => {
  return (
    <div className="bg-monochrome-100 p-4 flex justify-center items-center rounded-lg mb-0 h-[calc(100vh-265px)] lg:h-[calc(100vh-175px)]">
      <div className="lg:w-1/2 flex flex-col items-center">
        <p className="text-center text-lg">You don&apos;t have access to this page.</p>
      </div>
    </div>
  );
};

export default MarketplacePage;
