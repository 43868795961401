import React, { useState, useEffect } from "react";
import { Modal } from "flowbite-react";
import Button from "@/components/UI/Button";
import CustomCheckbox from "./custom-checkbox";
import IcCross from "@/assets/ic_cross";
import { GoArrowUpRight } from "react-icons/go";

interface PresalePageProps {
  userData: {
    kycStatus: string;
    walletDetails?: {
      isWalletWhitelisted: boolean;
    };
  };
}

const PresalePage: React.FC<PresalePageProps> = ({ userData }) => {
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [agreement, setAgreement] = useState(false);
  const [smartContractAgreement, setSmartContractAgreement] = useState(false);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [showTermsPrompt, setShowTermsPrompt] = useState(false);
  const [showIframe, setShowIframe] = useState(false);

  // Check if user has already agreed to terms
  useEffect(() => {
    const termsAgreed = localStorage.getItem('termsAgreed');
    if (termsAgreed) {
      setHasAgreedToTerms(true);
      setShowIframe(true);
      setShowTermsPrompt(false); // Ensure terms prompt is hidden if already agreed
    } else {
      setShowTermsPrompt(true);
    }
  }, []);

  // Modified useEffect to only show modal if terms haven't been agreed to yet
  useEffect(() => {
    const termsAgreed = localStorage.getItem('termsAgreed');
    if (
      userData.walletDetails?.isWalletWhitelisted === true && 
      userData.kycStatus === "Verified" &&
      !termsAgreed && // Check localStorage instead of state
      !showIframe // Prevent modal from showing if iframe is visible
    ) {
      setShowTermsModal(true);
    }
    // eslint-disable-next-line
  }, [userData]);

  const handleClose = () => {
    setShowTermsModal(false);
    setShowTermsPrompt(true);
    setSmartContractAgreement(false);
    setShowIframe(false);
  };

  const handleAgree = () => {
    localStorage.setItem('termsAgreed', 'true');
    setHasAgreedToTerms(true);
    setShowTermsModal(false);
    setShowTermsPrompt(false);
    setShowIframe(true);
  };

  const handleOpenTerms = () => {
    setShowTermsModal(true);
    setShowTermsPrompt(false);
    setShowIframe(false);
  };

  const renderMessage = () => {
    if (showTermsModal) return null; // Return null when terms modal is open

    if (userData.kycStatus !== "Verified" && userData.walletDetails?.isWalletWhitelisted === false) {
      return "You need to first complete your KYC and whitelist your external wallet to participate in the PNMO presale.";
    }
    if (userData.walletDetails?.isWalletWhitelisted === true && userData.kycStatus === "Verified" && !hasAgreedToTerms) {
      return "You have completed the whitelisting process, the sale page access is being activated for your wallet. Please revisit later in the next hours as described in the email titled: ’penomo Community Sale LIVE’  (can take up to 24 hours).";
    }
    if (userData.walletDetails?.isWalletWhitelisted === false && userData.kycStatus === "Verified") {
      return "Please whitelist your external wallet address before participating in the PNMO presale.";
    }
    return "Coming Soon.";
  };

  const renderContent = () => {
    if (showTermsModal) return null; // Return null when terms modal is open

    if (showTermsPrompt && userData.walletDetails?.isWalletWhitelisted === true && 
      userData.kycStatus === "Verified") {
      return (
        <div className="lg:w-1/2 flex flex-col items-center gap-4">
          <p className="text-center text-lg">You have to Accept the Terms and Conditions to buy PNMO presale tokens</p>
          <Button
            onClick={handleOpenTerms}
            classNames="btn btn-primary w-max"
          >
            Accept T&Cs
          </Button>
        </div>
      );
    }

    if (
      userData.walletDetails?.isWalletWhitelisted === true && 
      userData.kycStatus === "Verified" && 
      hasAgreedToTerms &&
      showIframe
    ) {
      return (
        <div className="w-full h-full flex justify-center">
          <div className="w-full h-full relative">
            {/* <iframe 
              src="https://tokentool.bitbond.com/tokensale/0xBD918DB7a2a8FC8D69cA1BBD16658D08B622F3e9?chainId=11155111&bgColorPrimary=000000&bgColorSecondary=282C35&borderColor=00CC9C&ctaPrimary=00CC9C&ctaFontColor=FFFFFF&fontColorPrimary=FFFFFF&fontColorSecondary=FFFFFF" 
              className="absolute inset-0 w-full h-full" 
              name="tokensale"
            ></iframe> */}
            <iframe src="https://tokentool.bitbond.com/tokensale/0x03D309e443c60F70840BaF36389FD2b45d28Ef07?chainId=56" className="absolute inset-0 w-full h-full"  name="tokensale"></iframe>


          </div>
        </div>
      );
    }
    
    return (
      <div className="lg:w-1/2 flex flex-col items-center">
        <p className="text-center text-lg">{renderMessage()}</p>
      </div>
    );
  };

  return (
    <>
      <div className="bg-monochrome-100 p-4 flex justify-center items-center rounded-lg mb-0 h-[calc(100vh-265px)] lg:h-[calc(100vh-175px)]">
        {renderContent()}
      </div>

      <Modal show={showTermsModal} onClose={handleClose} position="center" size="xl">
        <Modal.Body className="p-4 bg-monochrome-100">
          <div className="flex justify-between items-center pb-5">
            <h1 className="mb-0">Terms and Conditions</h1>
            <Button 
              classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" 
              onClick={handleClose}
            >
              <IcCross />
            </Button>
          </div>
          <hr className="border-monochrome-40" />

          <div className=" space-y-4">
            <div>
              {/* <h5 className="my-1 font-bold pt-6 text-sm">PNMO whitepaper</h5> */}
              <div className="my-4">
                <a 
                  href="https://docsend.com/view/sadh5kbpmic8jww6" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="text-primary-500 underline"
                >
                  <span className="flex items-center">
                    Community sale Terms & Conditions <GoArrowUpRight />
                  </span>
                </a>
              </div>
              <CustomCheckbox
                checked={smartContractAgreement}
                onChange={() => setSmartContractAgreement(!smartContractAgreement)}
                label="I confirm that I have thoroughly read, understood, and accept the Community Sale Legal Terms & Conditions, and acknowledge that this agreement is legally binding.*"
              />

              <div className="flex justify-end gap-4 mt-4">
                <Button onClick={handleClose} classNames="btn !bg-monochrome-60 !text-white">
                  Cancel
                </Button>
                <Button
                  onClick={handleAgree}
                  classNames="btn btn-primary w-max"
                  disabled={!smartContractAgreement}
                >
                  Agree & Continue
                </Button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PresalePage;