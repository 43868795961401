const RawTokenListItem = ({item, key}: any) => {
    // Helper function to convert wei to ether
const formatWeiToEther = (weiValue) => {
    const etherValue = weiValue / Math.pow(10, 18); // Divide by 10^18 to convert wei to ether
    return etherValue; // Format to 4 decimal places (adjust as needed)
  };
  
    return (
        <div className="grid grid-cols-4 text-white py-3 border-b border-monochrome-40" key={key}>
            <span className="flex gap-2 sm:gap-4 items-center"><img className='h-7' src={item.icon}/> {item.tokenSymbol}</span>
            <span className="text-end">{10 /* Replace this with actual APY data */}%</span>
            <span className="text-end">{formatWeiToEther(item.tokenBalance)}</span>
            <span className="text-end">${(formatWeiToEther(item.tokenBalance))*500}</span>
        </div>
    )
}

export default RawTokenListItem