import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import { format, isValid, parse } from "date-fns";
import { FaCalendarAlt } from "react-icons/fa";

const months = [
  { value: 0, label: "January" },
  { value: 1, label: "February" },
  { value: 2, label: "March" },
  { value: 3, label: "April" },
  { value: 4, label: "May" },
  { value: 5, label: "June" },
  { value: 6, label: "July" },
  { value: 7, label: "August" },
  { value: 8, label: "September" },
  { value: 9, label: "October" },
  { value: 10, label: "November" },
  { value: 11, label: "December" },
];

const formatDate = (date: any) =>
  date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

const getPreviousMonthDetails = (monthsAgo: any) => {
  const now = new Date();
  const start = new Date(now.getFullYear(), now.getMonth() - monthsAgo, 1);
  const end = new Date(now.getFullYear(), now.getMonth() - monthsAgo + 1, 0);
  const label = start.toLocaleString("en-US", { month: "short", year: "2-digit" });
  return { label, date: `${formatDate(start)} - ${formatDate(end)}` };
};

const currentDate = new Date();

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 11 }, (_, i) => currentYear + i);

const DateRangePicker = ({ dateRange, setDateRange, customDate, setCustomDate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [inputValues, setInputValues] = useState([format(customDate[0], "dd/MM/yyyy"), format(customDate[1], "dd/MM/yyyy")]);

  const [activeStartDate, setActiveStartDate] = useState(new Date(selectedYear, selectedMonth));

  useEffect(() => {
    setInputValues([format(customDate[0], "dd/MM/yyyy"), format(customDate[1], "dd/MM/yyyy")]);
  }, [customDate]);

  const handleMonthYearChange = (e: any, setter: any, type: "month" | "year") => {
    const newValue = Number(e.target.value);
    setter(newValue);

    if (type === "month") {
      setActiveStartDate(new Date(selectedYear, newValue));
    } else if (type === "year") {
      setActiveStartDate(new Date(newValue, selectedMonth));
    }
  };

  const handleOptionClick = (option: any) => {
    setDateRange(option.value);
    setIsOpen(option.value === "custom");
  };

  const handleDateInputChange = (index: any, e: any) => {
    const value = e.target.value;
    setInputValues((prev) => {
      const newInputValues = [...prev];
      newInputValues[index] = value;
      return newInputValues;
    });
  };

  const handleDateInputBlur = (index: any) => {
    const parsedDate = parse(inputValues[index], "dd/MM/yyyy", new Date());
    if (isValid(parsedDate)) {
      setCustomDate((prev: any) => {
        const newDates = [...prev];
        newDates[index] = parsedDate;
        return newDates;
      });
    } else {
      setInputValues((prev) => {
        const newInputValues = [...prev];
        newInputValues[index] = format(customDate[index], "dd/MM/yyyy");
        return newInputValues;
      });
    }
  };

  const dateRangeOptions = [
    {
      value: "currentActivity",
      label: "Current activity",
       date: `${formatDate(new Date())} - ${formatDate(new Date(new Date().setDate(new Date().getDate() + 1)))}`,
    },    
    {
      value: "thisWeek",
      label: "This week",
      date: `${formatDate(new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay())))} - ${formatDate(new Date())}`,
    },
    {
      value: "lastWeek",
      label: "Last week",
      date: `${formatDate(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 7)))} - ${formatDate(
        new Date(new Date().setDate(new Date().getDate() - new Date().getDay() - 1))
      )}`,
    },
    {
      value: "thisMonth",
      label: "This month",
      date: `${formatDate(new Date(currentDate.getFullYear(), currentDate.getMonth(), 1))} - ${formatDate(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
      )}`,
    },
    {
      value: "previousMonth1",
      label: `Statement - ${getPreviousMonthDetails(1).label}`,
      date: getPreviousMonthDetails(1).date,
    },
    {
      value: "previousMonth2",
      label: `Statement - ${getPreviousMonthDetails(2).label}`,
      date: getPreviousMonthDetails(2).date,
    },
    {
      value: "previousMonth3",
      label: `Statement - ${getPreviousMonthDetails(3).label}`,
      date: getPreviousMonthDetails(3).date,
    },
    {
      value: "custom",
      label: "Custom date range",
      date: `${formatDate(customDate[0])} - ${formatDate(customDate[1])}`,
    },
  ];

  const selectedOption = dateRangeOptions.find((option) => option.value === dateRange);

  return (
    <div className="relative inline-block w-full sm:w-max">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full text-[0.688rem] rounded-md bg-[#1A1C22] border border-transparent focus:border focus:border-monochrome-40 p-2.5 text-left text-sm focus-visible:outline-none flex gap-2 items-center ${
          isOpen ? "border-monochrome-40" : ""
        }`}
      >
        {selectedOption?.date}
        <FaCalendarAlt className="text-white" />
      </button>
      {isOpen && (
        <div
          className={`absolute z-10 mt-1 w-full ${
            dateRange === "custom" ? "md:min-w-[750px] md:max-w-[800px]" : "md:w-max"
          } bg-[#1A1C22] border-2 border-monochrome-40 rounded-lg shadow-lg`}
        >
          <div className="flex flex-col md:flex-row">
            <div className={`${dateRange === "custom" ? "md:border-r md:border-r-[#282C35] md:flex-[0_0_40%] md:w-2/5" : "w-full pb-4"}`}>
              {dateRangeOptions.map((option) => (
                <div
                  key={option.value}
                  onClick={() => handleOptionClick(option)}
                  className={`p-3 cursor-pointer text-sm flex items-center hover:bg-monochrome-60 ${option.value === dateRange ? "bg-monochrome-60" : ""}`}
                >
                  <span className={`mr-2 text-[#00cc9c] inline-flex justify-center ${option.value === dateRange ? "visible size-5" : "invisible"}`}>
                    &#10004;
                  </span>
                  <div>
                    <div>{option.label}</div>
                    <div className="text-[#9E9E9E]">{option.date}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className={`${dateRange === "custom" ? "md:flex-[0_0_60%] md:w-3/5" : ""}`}>
              {dateRange === "custom" && (
                <div className="p-4 h-full">
                  <div className="flex mb-4 month-year-dropdown gap-4 [&>select]:w-full sm:[&>select]:w-[48%]">
                    <select
                      value={selectedMonth}
                      onChange={(e) => handleMonthYearChange(e, setSelectedMonth, "month")}
                      className="p-2 bg-monochrome-60 !border-1 !border-monochrome-40 rounded-lg text-sm focus:ring-monochrome-40 focus:ring-1"
                    >
                      {months.map((month) => (
                        <option key={month.value} value={month.value}>
                          {month.label}
                        </option>
                      ))}
                    </select>
                    <select
                      value={selectedYear}
                      onChange={(e) => handleMonthYearChange(e, setSelectedYear, "year")}
                      className="p-2 bg-[#282C35] !border-2 !border-monochrome-40 rounded-lg text-sm focus:ring-monochrome-40 focus:ring-1"
                    >
                      {years.map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                  <Calendar
                    onChange={(dates: any) => setCustomDate(dates)}
                    selectRange
                    value={customDate}
                    className="p-0 h-3/4"
                    activeStartDate={activeStartDate}
                  />
                  <div className="flex items-center pt-3 transactions-date gap-2 [&>input]:w-[45%] border-t border-t-monochrome-40">
                    {[0, 1].map((index) => (
                      <div key={index} className="relative w-[45%]">
                        <input
                          type="text"
                          value={inputValues[index]}
                          onChange={(e) => handleDateInputChange(index, e)}
                          onBlur={() => handleDateInputBlur(index)}
                          className="border border-monochrome-40 focus:!border-monochrome-40 bg-monochrome-5 rounded-md text-sm w-full text-start"
                        />
                        <FaCalendarAlt className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
                      </div>
                    ))}
                    <button
                      onClick={() => setIsOpen(false)}
                      className="p-1 bg-[#00cc9c] text-[#243c3f] rounded flex align-middle aspect-square w-[9%] justify-center hover:bg-[#00a884]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="w-6 h-6 group-hover:stroke-[#00a884] pt-1"
                      >
                        <path d="M3 12h18" />
                        <path d="M16 6l5 6-5 6" />
                      </svg>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
