import React from 'react'

const IcArrowUp = () => {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.16166 3.17159L3.50488 8.82837" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.75931 3.33176L9.16202 3.17134L9.00195 7.5744" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcArrowUp