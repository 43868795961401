import React from "react";

const GovernancePage = () => {
  return (
    <div className="bg-monochrome-100 p-4 flex justify-center items-center rounded-lg mb-0 h-[calc(100vh-265px)] lg:h-[calc(100vh-175px)]">
      <div className="lg:w-1/2 flex flex-col items-center">
        <p className="text-center text-lg">Coming Soon.</p>
      </div>
    </div>
  );
};

export default GovernancePage;
