const IcMergeCopy = (props: any) => {
    return (
        <div {...props}>
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.0445 6.60266H9.54591C9.13209 6.604 8.7358 6.75188 8.44417 7.01381C8.15254 7.27573 7.98944 7.63025 7.99073 7.99942V15.27C7.99073 16.0412 8.68713 16.6668 9.54591 16.6668H16.0445C16.4583 16.6655 16.8546 16.5176 17.1463 16.2557C17.4379 15.9937 17.601 15.6392 17.5997 15.27V7.99942C17.5997 7.22825 16.9033 6.60266 16.0445 6.60266Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.8753 6.60256V4.73013C13.8753 4.35968 13.7113 4.00437 13.4197 3.74274C13.2758 3.61316 13.1045 3.51027 12.9158 3.44001C12.7271 3.36975 12.5246 3.33351 12.3202 3.33337L5.82153 3.33337C5.40951 3.33337 5.01365 3.4804 4.72281 3.74274C4.43057 4.00524 4.26649 4.36016 4.26636 4.73013V12.0008C4.26636 12.3712 4.43036 12.7265 4.722 12.9881C5.01365 13.2505 5.40871 13.3975 5.82153 13.3975H7.9907" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}

export default IcMergeCopy