const IcArrowDownSort = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.90002 2.375V10.3251H6.15002V2.375H6.90002Z" fill="#9E9E9E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.51391 6.69482L6.52527 9.71872L9.53617 6.69482L10.0676 7.22402L6.52532 10.7816L2.98248 7.22407L3.51391 6.69482Z"
        fill="#9E9E9E"
      />
    </svg>
  );
};

export default IcArrowDownSort;
