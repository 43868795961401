import React, { useState } from "react";
import { Modal } from "flowbite-react";
import Button from "@/components/UI/Button";
import PaymentTokenModal from "../Dashboard/PaymentToken";
import successImage from "@/assets/success.png";
import infoImage from "@/assets/info.png";
import IcCross from "@/assets/ic_cross";
import { repayRemainingAmount, requestRefund } from "@/services/api";
import carbon from "@/assets/carbon_energy-renewable.png";

const PendingTokenCard = ({
  purchaseId,
  tokenAmount,
  isFundsReceived,
  isPurchaseRequestAccepted,
  tokenId,
  projectName,
  companyWalletId,
  projectData,
  transactionDetails,
  isRequestedForRefund,
  isWaitingForConfirmation,
  isRequestedRemainingAmount,
  refetch
}: any) => {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showRemainingAmountModal, setShowRemainingAmountModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalContent, setSuccessModalContent] = useState({ title: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);

  const handlePaymentModal = () => {
    if (!isWaitingForConfirmation) {
      setShowPaymentModal((prev) => !prev);
    }
  };

  const handleRemainingAmountModal = () => {
    setShowRemainingAmountModal((prev) => !prev);
  };

  const handleRefundModal = () => {
    setShowRefundModal((prev) => !prev);
  };

  const handleSuccessModal = (title: string, message: string) => {
    setSuccessModalContent({ title, message });
    setShowSuccessModal(true);
  };

  const handlePayRemainingAmount = async () => {
    setIsLoading(true);
    try {
      await repayRemainingAmount(purchaseId);
      handleRemainingAmountModal();
      handleSuccessModal("Payment Successful!", "Your remaining payment has been successfully processed.");
      refetch();
    } catch (error) {
      console.error("Error paying remaining amount:", error);
      handleSuccessModal("Payment Failed", "There was an error processing your payment. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestRefund = async () => {
    setIsLoading(true);
    try {
      await requestRefund(purchaseId);
      handleRefundModal();
      handleSuccessModal("Refund Requested", "Your refund request has been successfully submitted.");
      refetch();
    } catch (error) {
      console.error("Error requesting refund:", error);
      handleSuccessModal("Refund Request Failed", "There was an error submitting your refund request. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-monochrome-100 shadow-sm p-3 w-full flex-col lg:flex-row gap-4 overflow-x-auto  rounded-md flex items-center justify-between">
      {/* Card content (same as before) */}
      <div className="flex flex-row justify-between gap-4 w-[100%] xl:w-[60%] overflow-x-scroll">
        <div className="flex gap-2 items-center">
          <div className="flex justify-center items-center bg-monochrome-60 rounded-md size-[40px] overflow-hidden">
            <img src="https://picsum.photos/200/300" alt="purchase-image" className="w-full" />
          </div>
          <div className="flex flex-col">
            <p className="text-monochrome-20">Name</p>
            <p className="text-white font-bold">{projectName}</p>
          </div>
        </div>
        <div className="space-y-1">
          <p className="text-monochrome-20">Token Amount</p>
          <p className="text-white font-bold">{tokenAmount}</p>
        </div>
        <div className="space-y-1">
          <p className="text-monochrome-20">Payment Amount</p>
          <p className="text-white font-bold">${tokenAmount * 500}</p>
        </div>
        <div className="space-y-1">
          <p className="text-monochrome-20">APY</p>
          <p className="text-white font-bold">10%</p>
        </div>
      </div>

      {/* Conditional rendering of buttons */}
      <div className="flex  flex-col md:flex-row gap-2 justify-end  w-[100%] xl:w-[30%]">
        {isWaitingForConfirmation ? (
          <Button classNames="bg-gray-500 rounded-md text-nowrap text-white p-2 cursor-not-allowed">
            Waiting to Confirm Payment
          </Button>
        ) : isRequestedForRefund ? (
          <Button classNames="bg-gray-500 rounded-md text-nowrap text-white p-2 cursor-not-allowed">
            Refund Requested
          </Button>
        ) : !isPurchaseRequestAccepted ? (
          <Button classNames="bg-gray-500 rounded-md text-nowrap text-white p-2 cursor-not-allowed">
            Purchase Requested
          </Button>
     
        ) : isRequestedRemainingAmount && !isWaitingForConfirmation ? (
          <div className="flex gap-2">
            <Button classNames="bg-green-500 rounded-md text-nowrap text-black p-2" onClick={handleRemainingAmountModal}>
              Pay Remaining Amount
            </Button>
            <Button classNames="bg-red-500 rounded-md text-nowrap text-white p-2" onClick={handleRefundModal}>
              Request Refund
            </Button>
          </div>
        ) : (
          <Button classNames="bg-green-500 rounded-md text-nowrap text-black p-2" onClick={handlePaymentModal}>
            Complete Payment
          </Button>
        )}
      </div>


      {/* Modals */}
      <PaymentTokenModal
        refetch={refetch}
        tokenAmount={tokenAmount}
        projectData={projectData}
        show={showPaymentModal}
        handleModal={handlePaymentModal}
        purchaseId={purchaseId}
        companyWalletId={companyWalletId}
      />


      {/* Pay Remaining Amount Modal */}

      <Modal show={showRemainingAmountModal} onClose={handleRemainingAmountModal} position="center" size="xl">
        <Modal.Body className="p-4 bg-monochrome-100">
          <div className="flex justify-between items-center pb-5">
            <h1 className="mb-0">Repay Remaining Amount</h1>
            <Button classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" onClick={handleRemainingAmountModal}>
              <IcCross />
            </Button>
          </div>
          <hr className="border-monochrome-40" />
          <div className="my-4 space-y-4">
            {/* Token Information */}
            <h5 className="my-1 font-bold">Token</h5>
            <div className="w-full flex justify-between rounded-md border border-monochrome-40 p-2">
              <div className="flex gap-2 items-center">
                <div className="flex justify-center items-center bg-monochrome-60 p-2 rounded-full">
                  <img style={{ width: "22px", height: "22px" }} src={carbon} alt="Token" />
                </div>
                <div className="flex flex-col">
                  <p>{projectData?.assetIds[0]?.assetType || "Token Type"}</p>
                </div>
              </div>
              <div className="flex gap-2 items-center">
                <div className="py-1 px-2 h-max w-max flex justify-center items-center rounded-xl bg-monochrome-60">
                  <p>$500/token</p>
                </div>
                <div className="py-1 px-2 h-max w-max flex justify-center items-center rounded-xl bg-[#00CC9C20]">
                  <p>APY: 10%</p>
                </div>
              </div>
            </div>

            {/* Purchase Details */}
            <h5 className="mb-2 mt-3 font-bold">Purchase Details</h5>
            <h6>Amount Already Sent</h6>
            <div className="w-full flex justify-between gap-5 items-center rounded-md border border-monochrome-40 bg-monochrome-100 p-2 overflow-hidden">
              <input
                type="text"
                className="w-max !text-2xl !font-bold !outline-none !hover:outline-none !bg-inherit !border-none"
                value={`$${transactionDetails?.amountTransferredByInvestor}`}
                readOnly
              />
            </div>

            <h6>Remaining Amount to Send</h6>
            <div className="w-full flex justify-between gap-5 items-center rounded-md border border-monochrome-40 bg-monochrome-100 p-2 overflow-hidden">
              <input
                type="text"
                className="w-max !text-2xl !font-bold !outline-none !hover:outline-none !bg-inherit !border-none"
                value={`$${transactionDetails?.remaingAmountToPay}`}
                readOnly
              />
            </div>

            {/* Bank Details */}
            <h5 className="my-1 font-bold">Bank Details</h5>
            <div className="w-full p-4 space-y-2 items-center rounded-md border border-monochrome-40 bg-monochrome-100 overflow-hidden">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-monochrome-20">Account Holder Name</p>
                  <p className="text-white font-bold">{projectData?.companyId?.bankAccountHolderName || "Account Holder Name"}</p>
                </div>
                <div className="text-right">
                  <p className="text-monochrome-20">Bank Name</p>
                  <p className="text-white font-bold">{projectData?.companyId?.bankName || "Bank Name"}</p>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-monochrome-20">Account Number</p>
                  <p className="text-white font-bold">{projectData?.companyId?.bankAccountNumber || "Account Number"}</p>
                </div>
                <div className="text-right">
                  <p className="text-monochrome-20">SWIFT/BIC Code</p>
                  <p className="text-white font-bold">{projectData?.companyId?.bankSwiftBicCode || "SWIFT/BIC Code"}</p>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-monochrome-20">IBAN</p>
                  <p className="text-white font-bold">{projectData?.companyId?.bankIban || "IBAN"}</p>
                </div>
              </div>
            </div>
          </div>

          <Button classNames="btn btn-primary w-full" onClick={handlePayRemainingAmount}>
            Complete Payment
          </Button>
        </Modal.Body>
      </Modal>

      {/* Request Refund Modal */}
      <Modal show={showRefundModal} onClose={handleRefundModal} position="center" size="xl">
        <Modal.Body className="p-4 bg-monochrome-100">
          <div className="flex justify-between items-center pb-5">
            <h1 className="mb-0">Request Refund</h1>
            <Button classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" onClick={handleRefundModal}>
              <IcCross />
            </Button>
          </div>
          <hr className="border-monochrome-40" />
          <div className="my-6 text-center">
            <p className="text-white">Are you sure you want to request a refund?</p>
          </div>
          <div className="flex justify-center gap-4 mt-4">
            <Button classNames="btn btn-primary" onClick={handleRequestRefund}>
              Yes, Request Refund
            </Button>
            <Button classNames="btn btn-secondary" onClick={handleRefundModal}>
              No, Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Success Modal */}
      <Modal show={showSuccessModal} onClose={() => setShowSuccessModal(false)} position="center" size="xl">
        <Modal.Body className="p-4 bg-monochrome-100">
          <div className="flex justify-between items-center pb-5">
            <h1 className="mb-0">Transaction Details</h1>
            <Button classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" onClick={() => setShowSuccessModal(false)}>
              <IcCross />
            </Button>
          </div>
          <hr className="border-monochrome-40" />
          <div className="my-6 flex flex-col justify-center items-center text-center space-y-6">
            <img src={successImage} alt="Success" className="mx-auto mb-4" />
            <h3 className="text-lg font-bold">{successModalContent.title}</h3>
            <p className="text-white mt-2">{successModalContent.message}</p>
          </div>
          <div className="flex justify-center mt-4">
            <Button classNames="btn btn-primary w-full" onClick={() => setShowSuccessModal(false)}>
              OK
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PendingTokenCard;