import React, { useEffect, useReducer, useRef, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import IcCross from "@/assets/ic_cross";
import Loader from "./Loader";
import { fetchAllNotifications, readNotification } from "@/services/api";
import useStore from "@/store/useStore";
import IcAcArrowDown from "@/assets/menu/ic_ac_arrow_down";
import { IcPercentage } from "@/assets/menu";
import IcAcArrowUp from "@/assets/menu/ic_ac_arrow_up";
import Button from "./UI/Button";
import { useNavigate } from "react-router-dom";
import { useNetwork } from "@/provider/NetworkContext";

const NotificationCard = ({ onClose }) => {
  const navigate = useNavigate();
  const { userData } = useStore();
  const queryClient = useQueryClient();
  const { showAllNotification, setShowAllNotifications } = useNetwork();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [charLimit, setCharLimit] = useState(130);

  const { data: notifications = [], refetch } = useQuery({
    queryKey: ["getNotifications", userData?._id],
    queryFn: () => fetchAllNotifications(userData?._id),
    enabled: !!userData?._id,
    select: (data) => data.data,
    refetchOnWindowFocus: true,
  });

  let sortedNotifications = notifications.sort((a, b) => dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix());
  const latestNotification = sortedNotifications[0];

  useEffect(() => {
    setShowAll(false);
    setShowAllNotifications(false);
    // eslint-disable-next-line
  }, [onClose]);

  const handleDeleteClick = (notificationId) => {
    sortedNotifications = sortedNotifications.filter((item) => item._id !== notificationId);

    setDeleteLoading(false);

    readNotification(notificationId)
      .then(() => {
        queryClient.invalidateQueries(["getNotifications"]);
      })
      .catch((error) => {
        console.error("Error deleting notification:", error);
      });
  };

  console.log("sortedNotifications: ", sortedNotifications);

  const handleViewDetailClick = (item) => {
    if (item.status === "Peaq Rewards Claimed") {
      window.open("https://app.galxe.com/quest/peaq/GCpEitg8Ms", "_blank");
    } else if (item.status === "Customer Onboarded") {
      navigate("/marketplace");
    } else if (item.status === "Welcome") {
      // navigate("/marketplace");
      window.open(
        `https://signup.getsilt.com/?company_app_id=e50d6912-5c56-40c8-8724-78503d5da4cb&customer_user_id=${item.userId}&hide_redirect_button=true`,
        "_blank"
      );
    } else if (item.status === "Referral Reward") {
      navigate("/campaign?section=leaderboard");
    } else if (
      ["Token Purchase Request Accepted", "Remaining Amount Request", "Remaining Payment Received", "Refund Request Processed"].includes(item.status)
    ) {
      navigate("/portfolio?tab=requests");
    } else if (item.status === "Refund Processed") {
      navigate("/settings?tab=bank");
    } else if (item.status === "KYC Status Update" || (item.status === "Welcome" && item.messages === "Welcome to penomo, Vijay Ladva!")) {
    } else if (item.status === "Wallet Whitelist Successfull") {
      navigate("/presale");
    } else if (item.status === "Revenue Share Distribution") {
      navigate("/portfolio");
    } else if (item.status === "Token Transfer Completed") {
      navigate("/portfolio");
    }
  };

  const ref = useRef(null);

  const [height, setHeight] = useState(90);

  useEffect(() => {
    function updateSize() {
      if (ref.current) ref.current.dataset.triggerRerender = String(Date.now());
      if (ref.current?.clientHeight) setHeight(ref.current?.clientHeight);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
    // eslint-disable-next-line
  }, [ref?.current]);

  useEffect(() => {
    // Function to set character limit based on screen width
    const updateCharLimit = () => {
      if (window.innerWidth <= 768) {
        // Tablet and below
        setCharLimit(75);
      } else {
        // Above tablet
        setCharLimit(130);
      }
    };

    // Initialize on mount and listen for resize events
    updateCharLimit();
    window.addEventListener("resize", updateCharLimit);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener("resize", updateCharLimit);
  }, []);

  const shouldShowButton = (status) => {
    return [
      "Peaq Rewards Claimed",
      "Customer Onboarded",
      "Token Purchase Request Accepted",
      "Remaining Amount Request",
      "Remaining Payment Received",
      "Refund Request Processed",
      "Refund Processed",
      "Wallet Whitelist Successfull",
      "Revenue Share Distribution",
      "Token Transfer Completed",
      "Welcome",
      "Referral Reward",
    ].includes(status);
  };

  const getButtonText = (status) => {
    switch (status) {
      case "Peaq Rewards Claimed":
        return "View Peaq Rewards";
      case "Customer Onboarded":
        return "Invest";
      case "Referral Reward":
        return "View Reward";
      case "Welcome":
        return "Complete KYC";
      case "Token Purchase Request Accepted":
      case "Remaining Amount Request":
      case "Remaining Payment Received":
      case "Refund Request Processed":
        return "View Purchase";
      case "Refund Processed":
        return "View Bank Details";
      case "Wallet Whitelist Successfull":
        return "PNMO Presale";
      case "Revenue Share Distribution":
      case "Token Transfer Completed":
        return "View Portfolio";
      default:
        return null;
    }
  };

  return (
    <div className="">
      <section className="col-span-2 px-4 flex flex-col gap-4 rounded-lg">
        {/* {deleteLoading && (
          <div className="loader-center">
            <Loader isLoading={true} />
          </div>
        )} */}
        {sortedNotifications.length === 0 ? (
          <div className="text-center"></div>
        ) : (
          <div className={`flex flex-col gap-4 w-full mt-1`}>
            {!showAll && !showAllNotification && (
              <div
                className={`relative ${window.innerWidth > 768 ? "mb-1" : ""}`}
                style={{
                  height:
                    window.innerWidth < 768
                      ? `${height + 2}px`
                      : `${height + (sortedNotifications.length > 3 ? 36 : sortedNotifications.length > 2 ? 24 : 0)}px`,
                }}
              >
                {sortedNotifications.slice(0, window.innerWidth < 768 ? 1 : 3).map((item, index) => {
                  return (
                    <div
                      key={item._id}
                      onClick={() => setShowAll(sortedNotifications?.length > 1 ? true : false)}
                      className={`notification-card flex border border-monochrome-60 justify-between items-center p-4 mb-4 rounded-lg shadow-lg
                      absolute left-1/2 -translate-x-1/2 min-h-[92px] 
             ${index == 0 ? "w-full cursor-pointer" : index == 1 ? "w-[95%]" : "w-[90%]"}`}
                      ref={index == 0 ? ref : null}
                      style={{ zIndex: sortedNotifications.length - index, top: `${index * 16}px` }}
                    >
                      <div className={`w-[85%]  flex ${window.innerWidth < 768 ? "items-start" : "items-center"}`}>
                        <IcPercentage className={`w-4 h-4 ${window.innerWidth < 768 ? "mt-2" : ""}`} />
                        <div className="w-full notification-content flex md:items-center flex-grow flex-wrap text-ellipsis ml-3">
                          <p className="flex flex-col md:flex-row md:items-center text-sm ">
                            {index == 0 ? (
                              <p className="">{item.messages.length > charLimit ? `${item.messages.substring(0, charLimit) + "..."}` : item.messages}</p>
                            ) : (
                              <p className="text-ellipsis overflow-hidden">
                                {item.messages.length > charLimit ? `${item.messages.substring(0, charLimit) + "..."}` : item.messages}
                              </p>
                            )}
                            <span className="text-monochrome-20 mt-2 md:mt-0 md:ml-2">{dayjs(item.createdAt).format("DD/MM/YYYY")}</span>
                          </p>
                          {shouldShowButton(item.status) && (
                            <Button rounded classNames="mt-4 md:mt-0 block md:hidden flex self-start" primary onClick={() => handleViewDetailClick(item)}>
                              {getButtonText(item.status)}
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className="ml-4 flex flex-row gap-2 justify-center items-center">
                        {shouldShowButton(item.status) && (
                          <Button rounded classNames="hidden md:block" primary onClick={() => handleViewDetailClick(item)}>
                            {getButtonText(item.status)}
                          </Button>
                        )}
                        <IcCross
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(item._id);
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {(showAllNotification || showAll) && (
              <div
                className={`flex flex-col  rounded-md gap-4 transition-all duration-500 ${
                  showAll || showAllNotification ? "animate-slideDown" : "animate-slideUp"
                }`}
                // onClick={() => {
                //   setShowAll(true);
                //   setShowAllNotifications(true);
                // }}
              >
                <div className="flex flex-row justify-between">
                  <h3>All Notifications</h3>
                  <button
                    onClick={() => {
                      setShowAll(false);
                      setShowAllNotifications(false);
                    }}
                    className="flex items-center bg-monochrome-80 rounded-lg "
                  >
                    <span className="text-xs p-2">Show Less</span>
                  </button>
                </div>
                <div className={`flex flex-col gap-2 md:gap-2 ${showAll || showAllNotification ? "max-h-[60vh] lg:max-h-[70vh] overflow-y-scroll" : ""}`}>
                  {sortedNotifications.slice(0, showAll || showAllNotification ? sortedNotifications.length : 1).map((item) => (
                    <div
                      key={item._id}
                      style={{
                        borderRadius: "0px !important",
                      }}
                      className="notification-card flex justify-between items-center p-4 mb-4 rounded-lg shadow-lg w-full"
                    >
                      <IcPercentage />
                      <div className="notification-content flex-grow flex-wrap text-ellipsis ml-3">
                        <p className="text-sm flex-wrap">
                          {item.messages}
                          <span className="text-monochrome-20 ml-2">{dayjs(item.createdAt).format("DD/MM/YYYY")}</span>
                          {shouldShowButton(item.status) && (
                            <Button rounded classNames="block md:hidden flex self-start" primary onClick={() => handleViewDetailClick(item)}>
                              {getButtonText(item.status)}
                            </Button>
                          )}
                        </p>
                      </div>
                      <div className="ml-4 flex flex-row gap-2 justify-center items-center">
                        {shouldShowButton(item.status) && (
                          <Button rounded classNames="hidden md:block" primary onClick={() => handleViewDetailClick(item)}>
                            {getButtonText(item.status)}
                          </Button>
                        )}
                        <IcCross
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(item._id);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default NotificationCard;
