const IcTopUp = () => {
    return (<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_284_33)">
            <path d="M8.69128 5.64177V10.4074" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.0765 8.0246H6.30591" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.19995 8.0246C2.19995 3.15653 3.82311 1.53337 8.69118 1.53337C13.5593 1.53337 15.1824 3.15653 15.1824 8.0246C15.1824 12.8927 13.5593 14.5158 8.69118 14.5158C3.82311 14.5158 2.19995 12.8927 2.19995 8.0246Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_284_33">
                <rect width="16" height="16" fill="white" transform="translate(0.666626)" />
            </clipPath>
        </defs>
    </svg>
    )
}

export default IcTopUp;