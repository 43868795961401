import React from "react";
import IcBuy from "@/assets/ic_buy";
import IcCross from "@/assets/ic_cross";
import IcPending from "@/assets/ic_pending";
import IcSell from "@/assets/ic_sell";
import { IcDropdownChecked } from "@/assets/menu";
import { ITransaciton } from "@/types/transactions";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

// Extend dayjs with customParseFormat plugin
dayjs.extend(customParseFormat);

interface ITransactionItemProps {
  item: ITransaciton;
  onClick: () => void;
  isSelected: boolean;
  uniqueId: string;
  currentUserId: string;
  currentWalletAddress: string;
}

const TransactionItem: React.FC<ITransactionItemProps> = ({ item, onClick, isSelected, uniqueId, currentUserId, currentWalletAddress }) => {
  const parsedDate = dayjs(item?.updatedAt || item?.createdAt);
  const isTokenTransaction = item?.transactionType === 'token transaction';

  const isOnChain = isTokenTransaction || item?.transactionMethod === "ON_CHAIN";

  const isIncomingTransaction = () => {
    if (isTokenTransaction) {
      return (item?.to?.toLowerCase() || '') === (currentWalletAddress?.toLowerCase() || '');
    } else if (isOnChain) {
      return (item?.toAddress?.toLowerCase() || '') === (currentWalletAddress?.toLowerCase() || '');
    } else {
      return item?.toUserId?._id === currentUserId;
    }
  };
  
  const isOutgoingTransaction = () => {
    if (isTokenTransaction) {
      return (item?.from?.toLowerCase() || '') === (currentWalletAddress?.toLowerCase() || '');
    } else if (isOnChain) {
      return (item?.fromAddress?.toLowerCase() || '') === (currentWalletAddress?.toLowerCase() || '');
    } else {
      return item?.fromUserId?._id === currentUserId;
    }
  };

  const transactionType = isIncomingTransaction() ? "Buy" : "Sell";
  const isAmountIncoming = item.paymentTokensTransferred > 0; // Positive amounts indicate incoming transactions
  const transactionColor = isIncomingTransaction() ? "text-green-500" : isOutgoingTransaction() ? "text-red-600" : "text-gray-400";
  const transactionSign = isIncomingTransaction() ? "+" : isOutgoingTransaction() ? "-" : "";

  // Handle amount display for both transaction types
  const getDisplayAmount = () => {
    if (isTokenTransaction) {
      // Convert wei to ETH for token transactions
      const amountInEth = parseFloat(item?.tokenAmount) / Math.pow(10, 18);
      return amountInEth.toFixed(2);
    }
    return Math.abs(item?.paymentTokensTransferred).toFixed(2);
  };

  let statusIcon;
  switch (isTokenTransaction ? "COMPLETED" : item?.status) {
    case "FAILED":
      statusIcon = <IcCross />;
      break;
    case "COMPLETED":
      statusIcon = <IcDropdownChecked />;
      break;
    case "PENDING":
      statusIcon = <IcPending />;
      break;
    default:
      statusIcon = null;
  }

  let transactionName;
  if (isTokenTransaction) {
    transactionName = "Security Token Transfer";
  } else {
    switch (item?.transactionPurpose) {
      case "SECURITY_TOKEN_PURCHASE":
        transactionName = "Security Token Purchase";
        break;
      case "DIVIDEND_PAYMENT":
        transactionName = "Dividend Payment";
        break;
      case "SECURITY_TOKEN_SALE":
        transactionName = "Sell Token";
        break;
      case "SECURITY_TOKEN_TRANSFER":
        transactionName = "Security Token Transfer";
        break;
      case "PAYMENT_TOKEN_TOP_UP":
        transactionName = "Top Up";
        break;
      case "PAYMENT_TOKEN_WITHDRAW":
        transactionName = "Withdraw";
        break;
      default:
        transactionName = null;
    }
  }

  return (
    <div
      className={`text-[14px] py-[14px] px-4 grid grid-cols-5 text-white border-b cursor-pointer border-monochrome-40 ${isSelected ? "bg-green-10" : ""}`}
      onClick={onClick}
      data-id={uniqueId}
    >
      <span>{parsedDate.format("MMM D, YYYY")}</span>
      <span className="flex items-center">
        {transactionType === "Buy" ? <IcBuy /> : <IcSell />}
        <span className="ml-2">{transactionName}</span>
      </span>
      <span>{isTokenTransaction ? item?.tokenSymbol : item?.paymentTokenName || "USD"}</span>
      <span>{parsedDate.format("hh:mm A")}</span>
      <span className={`flex gap-2 items-center ${transactionColor}`}>
        {transactionSign}
        {/* <span>${Math.abs(item.paymentTokensTransferred || item.tokenAmount).toFixed(2)}</span> */}
        ${getDisplayAmount()}
        {statusIcon}
      </span>
    </div>
  );
};

export default TransactionItem;
