const IcPenomoWhitelistJoined = (props: any) => {
    return (
        <div {...props}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.73342 15L4.46675 12.8667L2.06675 12.3333L2.30008 9.86667L0.666748 8L2.30008 6.13333L2.06675 3.66667L4.46675 3.13333L5.73342 1L8.00008 1.96667L10.2667 1L11.5334 3.13333L13.9334 3.66667L13.7001 6.13333L15.3334 8L13.7001 9.86667L13.9334 12.3333L11.5334 12.8667L10.2667 15L8.00008 14.0333L5.73342 15ZM7.30008 10.3667L11.0667 6.6L10.1334 5.63333L7.30008 8.46667L5.86675 7.06667L4.93341 8L7.30008 10.3667Z" fill="currentColor" />
            </svg>
        </div>
    )
}

export default IcPenomoWhitelistJoined