import React from "react";
import Modal from "./Modal";
import BankDetail from "./BankDetail";
import { IPortfolioRequest } from "@/types";

interface PayRemainingModalProps {
  data: IPortfolioRequest;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}



const PayRemainingModal = ({ data, isOpen, onClose, onConfirm }: PayRemainingModalProps) => {
  const remainingAmount = data.transactionDetails?.remaingAmountToPay || 0;
  const bankDetails = [
    {
      title: "Account Holder Name",
      value: data?.projectId?.companyId?.bankAccountHolderName,
    },
    {
      title: "Bank Name",
      value: data?.projectId?.companyId?.bankName,
    },
    {
      title: "Account Number",
      value: data?.projectId?.companyId?.bankAccountNumber,
      canCopy: true,
    },
    {
      title: "SWIFT/BIC Code",
      value: data?.projectId?.companyId?.bankSwiftBicCode,
      canCopy: true,
    },
    {
      title: "IBAN",
      value: data?.projectId?.companyId?.bankIban,
      canCopy: true,
    },
  ];

  return (
    <Modal isOpen={isOpen} title="Pay Remaining Amount" onClose={() => onClose()}>
      <div className="flex flex-col">
        <p className="text-[11px] font-normal text-white mb-2">Outstanding Amount</p>
        <div className="flex items-center justify-between px-3 py-2 border-[0.5px] border-monochrome-40 rounded-lg mb-4">
          <p className="text-white text-[26px] font-medium leading-[39px]">${remainingAmount.toLocaleString()} USD</p>
        </div>

        <p className="text-[11px] font-normal text-white mb-2">Bank Details</p>
        <BankDetail data={bankDetails} />

        <div className="w-full flex gap-4">
          <button
            className=" w-full px-6 py-2 rounded-lg bg-monochrome-60 border border-monochrome-40 text-white text-xs font-normal text-center"
            onClick={() => onClose()}
          >
            Back
          </button>
          <button
            className=" w-full px-6 py-2 rounded-lg bg-monochrome-60 border border-monochrome-40 text-white text-xs font-normal text-center"
            onClick={() => onConfirm()}
          >
            Complete Transfer
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PayRemainingModal;
