import React from "react";

const IcCopyFlat = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.089 5.28174H6.89014C6.55909 5.28281 6.24206 5.40112 6.00876 5.61065C5.77545 5.82019 5.64497 6.10381 5.646 6.39914V12.2156C5.646 12.8326 6.20312 13.3331 6.89014 13.3331H12.089C12.4201 13.332 12.7371 13.2137 12.9704 13.0041C13.2037 12.7946 13.3342 12.511 13.3332 12.2156V6.39914C13.3332 5.78221 12.7761 5.28174 12.089 5.28174Z"
        stroke="#9E9E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3537 5.28186V3.78391C10.3537 3.48755 10.2225 3.2033 9.98917 2.994C9.87402 2.89033 9.73702 2.80802 9.58605 2.75181C9.43508 2.69561 9.27313 2.66661 9.10954 2.6665H3.91065C3.58103 2.6665 3.26434 2.78413 3.03167 2.994C2.79787 3.204 2.66661 3.48793 2.6665 3.78391V9.60041C2.6665 9.89677 2.7977 10.181 3.03102 10.3903C3.26434 10.6002 3.58038 10.7178 3.91065 10.7178H5.64598"
        stroke="#9E9E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IcCopyFlat;
