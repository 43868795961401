import React, { useState } from "react";
import IcCross from "@/assets/ic_cross";
import Button from "@/components/UI/Button";
import { Modal } from "flowbite-react";
import { formatNumber } from "@/utils";
import InvestPageOne from "./InvestModalPages/page-1";
import InvestPageTwo from "./InvestModalPages/page-2";
import InvestPageThree from "./InvestModalPages/page-3";
import useStore from "@/store/useStore";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchInvestor } from "@/services/api";

interface InvestNowModalProps {
  show: boolean;
  data: any;
  handleModal: () => void;
}

const InvestNowModal: React.FC<InvestNowModalProps> = ({ show, handleModal, data }) => {
  const navigate = useNavigate();
  const { userData, investorData } = useStore();
  // console.log("DATA: ", data);
  const { irr, tokenPrice, tokenAmount, projectId, tokenIds } = data;

  const tokenId = tokenIds[0]?._id;
  // console.log("Investor Data: ", investorData);
  const investorId = investorData._id;

  const [page, setPage] = useState(0);
  const [purchase, setPurchase] = useState(0);
  const [amount, setAmount] = useState(0);
  const [profit, setProfit] = useState(0);
  const [purchaseSuccess, setPurchaseSuccess] = useState(false);

  const handleNextPage = (success?: boolean) => {
    if (success !== undefined) {
      setPurchaseSuccess(success);
    }
    setPage((prevPage) => Math.min(prevPage + 1, 2));
  };

  const handlePrevPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleClose = () => {
    handleModal();
    setPage(0);
  };

  const calculateYearlyProfit = (investmentAmount: number) => {
    if (irr > 0) {
      const calculatedProfit = (irr / 100) * investmentAmount;
      setProfit(calculatedProfit);
    } else {
      setProfit(0);
    }
  };

  const handlePurchaseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const numericValue = Number(newValue);

    if (numericValue === 0 && newValue === "") {
      setPurchase(0);
      setAmount(0);
      setProfit(0);
    } else if (numericValue <= tokenAmount) {
      setPurchase(numericValue);
      const tokenValue = numericValue * tokenPrice;
      setAmount(tokenValue);
      calculateYearlyProfit(tokenValue);
    } else {
      setPurchase(tokenAmount);
      const tokenValue = tokenAmount * tokenPrice;
      setAmount(tokenValue);
      calculateYearlyProfit(tokenValue);
    }
  };

  const formatCurrency = (value: number): string => {
    return `$${formatNumber(value)}`;
  };

  const renderModalContent = () => {
    switch (page) {
      case 0:
        return (
          <InvestPageOne
            data={data}
            purchase={purchase}
            amount={amount}
            profit={profit}
            handlePurchaseChange={handlePurchaseChange}
            handleClose={handleClose}
            handleNextPage={handleNextPage}
            formatCurrency={formatCurrency}
          />
        );
      case 1:
        return (
          <InvestPageTwo
            handlePrevPage={handlePrevPage}
            handleNextPage={handleNextPage}
            projectId={projectId}
            investorId={investorId}
            tokenId={tokenId}
            purchase={purchase}
          />
        );
      case 2:
        return <InvestPageThree handleClose={handleClose} success={purchaseSuccess} />;
      default:
        return null;
    }
  };

  const { data: latestInvestorData = null } =
    useQuery({
      queryKey: ["getInvestor", investorData._id],
      queryFn: () => fetchInvestor(investorData._id),
      enabled: !!investorData._id,
    }) || {};

  const bankDetailsOfInvestorExists =
    latestInvestorData?.data?.bankAccountHolderName &&
    latestInvestorData?.data?.bankAccountNumber &&
    latestInvestorData?.data?.bankName &&
    latestInvestorData?.data?.bankIban &&
    latestInvestorData?.data?.bankSwiftBicCode &&
    latestInvestorData?.data?.bankDefaultCurrency

  return (
    <Modal show={show} onClose={handleClose} position="center" size="xl">
      <Modal.Body className="p-4 bg-monochrome-100">
        <div className="flex justify-between items-center pb-5">
          <h1 className="mb-0">Invest</h1>
          <Button classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" onClick={handleClose}>
            <IcCross />
          </Button>
        </div>
        <hr className="border-monochrome-40" />

        <div className="my-4 space-y-4">
        {renderModalContent()}
          {/* {bankDetailsOfInvestorExists ? (
            {renderModalContent()}
          ) : (
            <div className="text-center text-sm">
              <p>Bank Details not found </p>
              <button className="btn btn-primary w-full text-xs mt-4" onClick={() => navigate("/settings?tab=bank")}>
                Add Bank Details
              </button>
            </div>
          )} */}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default InvestNowModal;
