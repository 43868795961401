import React from 'react'
interface IconProps {
    className?: string;
  }
  
  const IcSell: React.FC<IconProps> = ({ className }) => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <rect x="0.506714" width="16" height="16" rx="8" fill="#482226" />
            <path d="M5.6783 10.8283L11.3351 5.17151" stroke="#FF1E1E" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.0811 10.6682L5.67843 10.8286L5.8385 6.42554" stroke="#FF1E1E" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IcSell