import "./marketplaceContent.css";
// import iconWallet from "../../../../../assets/iconWallet.png";
// import iconWallet3 from "../../../../../assets/iconWallet3.png";
import carbon_energy from "../../../../../assets/carbon_energy-renewable.svg";
import WorldFlag from "react-world-flags";
import lookup from "country-code-lookup";
import { IcPercentage } from "@/assets/menu";
import { IcLabel } from "@/assets";
import { useEffect, useState } from "react";
import InvestNowModal from "./InvestNowModal";

interface MarketplaceContentProps {
  overviewRef: any;
  updatesRef: any;
  documentsRef: any;
  financialsRef: any;
  detailsRef: any;
  projectData: any;
}

const MarketplaceContent = (props: MarketplaceContentProps) => {
  const [showModal, setShowModal] = useState(false);
  const { projectData } = props;

  if (!projectData) {
    console.error("Project data is undefined");
    return null;
  }

  const {
    name,
    tokenPrice,
    irr,
    revenueStreams = [],
    spvLocation = "",
    assetLocation = "",
    assetIds = [],
    documentRequestId = [],
    symbol,
    annualOperationCosts,
  } = projectData;

  const newObj = {
    name,
    tokenPrice,
    irr,
    revenueStreams,
    spvLocation,
    assetLocation,
    assetIds,
    documentRequestId,
  };
  // console.log(newObj);
  const getCountryCode = (countryName: string) => {
    // console.log("Country in COde: ", countryName);
    const country = lookup.byCountry(countryName);
    // console.log("Country: ", country);
    return country?.iso2;
  };

  // Handle Modal Function
  const handleModal = () => {
    setShowModal((state) => !state);
  };

  const formatDocumentName = (name: string) => {
    // Convert underscore to space, capitalize the first letter of each word
    return name
      .split("_") // Split by underscore
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and lowercase the rest
      .join(" "); // Join with space
  };

  const formatNumber = (number: number | string) => {
    if (number == null) return "N/A";
    return Number(number).toLocaleString(); // Format number with commas
  };

  // Calculate total projected revenue
  const calculateTotalProjectedRevenue = () => {
    return revenueStreams.reduce((total: number, item: any) => {
      if (item.monthlyRevenue) {
        return total + item.monthlyRevenue * 12;
      } else if (item.yearlyRevenue) {
        return total + item.yearlyRevenue;
      }
      return total;
    }, 0);
  };

  // Total projected revenue
  const totalProjectedRevenue = calculateTotalProjectedRevenue();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="text-white">
      <div className="md:grid md:grid-cols-12 gap-4">
        <div className="md:col-span-8 grid gap-4" style={{ width: "100%" }}>
          <div className="marketplace-card custom-card" ref={props.overviewRef}>
            <h5 className="card-title">Overviews</h5>
            <div className="card-body divide-y divide-monochrome-40 border-b border-monochrome-40">
              <div className="flex details-wraper">
                <span className="text-gray">Capital Needed</span>
                <span>{formatNumber(projectData.fundingGoal)} USD</span>
              </div>
              <div className="flex details-wraper">
                <span className="text-gray">Revenue Generating Assets</span>
                <span>{assetIds.length > 0 ? assetIds[0]?.assetType : "N/A"}</span>
              </div>
              <div className="flex details-wraper">
                <span className="text-gray">Total Financed</span>
                <span>{formatNumber(projectData.fundingReceived)} USD</span>
              </div>
            </div>
          </div>

          <div className="marketplace-card custom-card min-h-[256px]" ref={props.detailsRef}>
            <h5 className="card-title">Details</h5>
            <div className="card-body divide-y divide-monochrome-40 border-b border-monochrome-40">
              <div className="flex details-wraper">
                <span className="text-gray">Power Capacity</span>
                <span>{assetIds.length > 0 ? formatNumber(assetIds[0]?.powerCapacity) : "N/A"} kW</span>
              </div>
              <div className="flex details-wraper">
                <span className="text-gray">Type of Revenue and Project Revenues</span>
                <div style={{ width: "55%", padding: "0" }}>
                  {revenueStreams.map((item: any, index: number) => (
                    <div key={index} className="flex justify-between" style={{ marginBottom: "25px" }}>
                      <div>{item.name}</div>
                      <div className="text-gray">
                        {item.monthlyRevenue ? `${formatNumber(item.monthlyRevenue)} USD per month` : `${formatNumber(item.yearlyRevenue)} USD per year`}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex details-wraper">
                <span className="text-gray">Physical Location</span>
                <div className="flex items-center" style={{ gap: "10px" }}>
                  <WorldFlag code={getCountryCode(assetLocation)} style={{ width: "20px", height: "20px" }} />
                  <span>{assetLocation || "N/A"}</span>
                </div>
              </div>
              <div className="flex details-wraper">
                <span className="text-gray">SPV Location</span>
                <div className="flex items-center" style={{ gap: "10px" }}>
                  <WorldFlag code={getCountryCode(spvLocation)} style={{ width: "20px", height: "20px" }} />
                  <span>{spvLocation || "N/A"}</span>
                </div>
              </div>
              <div className="flex details-wraper">
                <span className="text-gray">Annual Operation Cost</span>
                <span>{annualOperationCosts ? formatNumber(annualOperationCosts) : "N/A"} USD</span>
              </div>
            </div>
          </div>

          <div className="marketplace-card custom-card" ref={props.financialsRef}>
            <h5 className="card-title">Financials</h5>
            <div className="card-body divide-y divide-monochrome-40 border-b border-monochrome-40">
              <div className="flex details-wraper">
                <span className="text-gray">Capital Needed</span>
                <span>{formatNumber(projectData.fundingGoal)} USD</span>
              </div>
              <div className="flex details-wraper">
                <span className="text-gray">Total Financed</span>
                <span>{formatNumber(projectData.fundingReceived)} USD</span>
              </div>
              <div className="flex details-wraper">
                <span className="text-gray">Percentage Funded</span>
                <span>{formatNumber((projectData.fundingReceived / projectData.fundingGoal) * 100)} %</span>
              </div>
              <div className="flex details-wraper">
                <span className="text-gray">Total Project Revenue</span>
                <span>{formatNumber(totalProjectedRevenue)} USD per year</span>
              </div>
            </div>
          </div>

          <div className="marketplace-card custom-card min-h-[256px]" ref={props.documentsRef}>
            <h5 className="card-title">Documents</h5>
            <div className="card-body divide-y divide-monochrome-40 border-b border-monochrome-40">
              {documentRequestId.length > 0 ? (
                documentRequestId.map((doc: any, index: number) =>
                  doc.documentPath ? (
                    <div key={index} className="flex details-wraper ">
                      <div className="text-gray">{formatDocumentName(doc.documentId.documentName)}</div>
                      <div>
                        <a href={doc.documentPath} target="_blank" rel="noopener noreferrer">
                          {doc.documentName}
                        </a>
                      </div>
                    </div>
                  ) : null
                )
              ) : (
                <div className="flex details-wrapper">
                  <span className="text-gray">No documents available</span>
                </div>
              )}
            </div>
          </div>

          <div className="marketplace-card custom-card " ref={props.updatesRef}>
            <h5 className="card-title">Updates</h5>
            <div className="card-body divide-y divide-monochrome-40">
              <div className="flex details-wraper">
                <span className="text-gray">No updates</span>
              </div>
            </div>
          </div>
        </div>

        {/* Investment Card Container */}
        <div className="md:col-span-4 invest-now-section mt-4 md:mt-0">
          {/* Investment Card */}
          <div className="marketplace-card custom-card invest-card">
            <div className="card-body" style={{ padding: "0" }}>
              <div className="flex" style={{ gap: "20px" }}>
                <img style={{ width: "32px", height: "32px" }} src={carbon_energy} alt="" />
                <div className="flex flex-col gap-2">
                  <h3 className="flex leading-7">{name || "N/A"}</h3>
                  <p className="flex text-md text-monochrome-20">{assetIds.length > 0 ? assetIds[0]?.assetType : "N/A"}</p>
                </div>
              </div>
              <div className="grid grid-cols-2 my-6">
                <div className="flex flex-col items-start gap-3">
                  <div className="flex flex-row  gap-2">
                    <div className="p-0.5 h-6 w-6 pt-[3px] flex justify-center items-center rounded-lg bg-[#00CC9C20]">
                      <IcLabel />
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="flex text-monochrome-20 text-[12px]">Token Price</p>
                      <p className="font-medium">${formatNumber(tokenPrice) || "N/A"}</p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-start gap-3">
                  <div className="flex flex-row gap-2">
                    <div className="p-0.5 h-6 w-6 pt-[3px] flex justify-center items-center rounded-lg bg-[#00CC9C20]">
                      <IcPercentage />
                    </div>
                    <div className="flex flex-col gap-2">
                      <p className="flex text-monochrome-20 text-[12px]">APY</p>
                      <p className="font-medium">{10}%</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button className="btn btn-primary w-full" style={{ marginTop: "15px" }} onClick={handleModal}>
                  Invest Now
                </button>
              </div>
              {showModal && <InvestNowModal show={showModal} handleModal={handleModal} data={projectData} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceContent;
