import IcBuy from "@/assets/ic_buy";
import IcCross from "@/assets/ic_cross";
import { IcDropdownChecked } from "@/assets/menu";
import Button from "@/components/UI/Button";
import { Modal } from "flowbite-react";

interface ContactSupportModalProps {
    show: boolean;
    handleClose: () => void;
}
const ContactSupportSucessModal = ({ show, handleClose }: ContactSupportModalProps) => {
    return (
        <Modal show={show} onClose={handleClose} position={'center'} size={'4xl'}>
            <Modal.Body className="p-4 bg-monochrome-100">
                <div className={"flex justify-between items-center pb-5"}>
                    <h1 className="mb-0">
                        Contact Support
                    </h1>
                    <Button classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" rounded
                        onClick={handleClose}>
                        <IcCross />
                    </Button>
                </div>
                <hr className="border-monochrome-40" />
                <section className="p-4 flex flex-col gap-7 items-center">
                    <IcDropdownChecked className={'h-10 w-10 bg-green-10 rounded-full p-2'}></IcDropdownChecked>
                    <div className="flex flex-col gap-2 items-center">
                        <h3>Submission Successful</h3>
                        <p className="text-sm w-[90%] text-center text-monochrome-20">Thank you for reaching out! We will review your inquiry and respond as soon as possible.</p>
                    </div>
                    <Button primary rounded onClick={handleClose} classNames="w-full">Close</Button>
                </section>
            </Modal.Body>
        </Modal>
    )
}

export default ContactSupportSucessModal