import React from "react";

const IcChecked = (props: any) => {
  return (
    <div {...props}>
      <svg width="20" height="18" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.62482 11.0625L8.86613 18.875L21.3748 3.25" stroke="currentColor" strokeWidth="4.6875" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
};

export default IcChecked;
