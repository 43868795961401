const IcHandsCoin = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4525_946742)">
        <path
          d="M18.1844 13.3344C17.9143 13.1275 17.6004 12.9856 17.2667 12.9195C16.933 12.8534 16.5887 12.8649 16.2602 12.9531L13.4375 13.5984V13.5C13.4375 12.837 13.1741 12.2011 12.7053 11.7322C12.2364 11.2634 11.6005 11 10.9375 11H7.02656C6.65717 10.999 6.29124 11.0713 5.94995 11.2126C5.60867 11.3539 5.2988 11.5616 5.03828 11.8234L3.35938 13.5H1.5625C1.1481 13.5 0.750671 13.6646 0.457646 13.9577C0.16462 14.2507 0 14.6481 0 15.0625L0 18.1875C0 18.6019 0.16462 18.9993 0.457646 19.2924C0.750671 19.5854 1.1481 19.75 1.5625 19.75H9.375C9.45165 19.75 9.528 19.7405 9.60234 19.7219L14.6023 18.4719C14.6502 18.4602 14.6969 18.4445 14.7422 18.425L17.775 17.1328C17.793 17.1258 17.8102 17.118 17.8273 17.1094C18.1665 16.9397 18.457 16.6865 18.6713 16.3736C18.8856 16.0607 19.0168 15.6985 19.0525 15.3209C19.0882 14.9433 19.0273 14.5629 18.8754 14.2153C18.7235 13.8678 18.4857 13.5647 18.1844 13.3344ZM2.8125 17.875H1.875V15.375H2.8125V17.875ZM17.0062 15.4227L14.0758 16.6727L9.25937 17.875H4.6875V14.8281L6.36406 13.1516C6.45072 13.064 6.5539 12.9944 6.66762 12.9469C6.78133 12.8995 6.90334 12.875 7.02656 12.875H10.9375C11.1033 12.875 11.2622 12.9409 11.3794 13.0581C11.4967 13.1753 11.5625 13.3343 11.5625 13.5C11.5625 13.6658 11.4967 13.8247 11.3794 13.942C11.2622 14.0592 11.1033 14.125 10.9375 14.125H8.75C8.50136 14.125 8.2629 14.2238 8.08709 14.3996C7.91127 14.5754 7.8125 14.8139 7.8125 15.0625C7.8125 15.3112 7.91127 15.5496 8.08709 15.7254C8.2629 15.9012 8.50136 16 8.75 16H11.25C11.3207 16 11.3912 15.9922 11.4602 15.9766L16.6945 14.7727L16.7313 14.7633C16.7846 14.7488 16.8406 14.7467 16.8949 14.7574C16.9492 14.768 17.0003 14.7909 17.0442 14.8245C17.0882 14.858 17.1239 14.9012 17.1484 14.9508C17.173 15.0003 17.1858 15.0549 17.1859 15.1102C17.1863 15.1735 17.1699 15.2358 17.1383 15.2907C17.1068 15.3456 17.0612 15.3911 17.0062 15.4227Z"
          fill="#00CC9C"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 4.5C14 6.9852 11.9852 9 9.5 9C7.0148 9 5 6.9852 5 4.5C5 2.0148 7.0148 0 9.5 0C11.9852 0 14 2.0148 14 4.5ZM9.2 3C9.04087 3 8.88826 3.06321 8.77574 3.17574C8.66321 3.28826 8.6 3.44087 8.6 3.6C8.6 3.75913 8.66321 3.91174 8.77574 4.02426C8.88826 4.13679 9.04087 4.2 9.2 4.2V3ZM9.8 2.4V2.1H9.2V2.4C8.88174 2.4 8.57652 2.52643 8.35147 2.75147C8.12643 2.97652 8 3.28174 8 3.6C8 3.91826 8.12643 4.22348 8.35147 4.44853C8.57652 4.67357 8.88174 4.8 9.2 4.8V6C8.939 6 8.7167 5.8335 8.6339 5.6001C8.62162 5.56191 8.6018 5.52658 8.57562 5.49619C8.54944 5.4658 8.51743 5.44096 8.48149 5.42315C8.44554 5.40535 8.40639 5.39493 8.36635 5.39252C8.32631 5.39011 8.2862 5.39575 8.24837 5.40911C8.21055 5.42247 8.1758 5.44329 8.14616 5.47032C8.11652 5.49735 8.0926 5.53005 8.07582 5.56648C8.05904 5.60292 8.04974 5.64235 8.04847 5.68244C8.04719 5.72253 8.05397 5.76247 8.0684 5.7999C8.15111 6.03392 8.30436 6.23653 8.50702 6.37982C8.70969 6.52312 8.9518 6.60004 9.2 6.6V6.9H9.8V6.6C10.1183 6.6 10.4235 6.47357 10.6485 6.24853C10.8736 6.02348 11 5.71826 11 5.4C11 5.08174 10.8736 4.77652 10.6485 4.55147C10.4235 4.32643 10.1183 4.2 9.8 4.2V3C10.061 3 10.2833 3.1665 10.3661 3.3999C10.3784 3.43809 10.3982 3.47342 10.4244 3.50381C10.4506 3.53421 10.4826 3.55904 10.5185 3.57685C10.5545 3.59465 10.5936 3.60507 10.6336 3.60748C10.6737 3.60989 10.7138 3.60425 10.7516 3.59089C10.7894 3.57753 10.8242 3.55671 10.8538 3.52968C10.8835 3.50265 10.9074 3.46995 10.9242 3.43352C10.941 3.39708 10.9503 3.35765 10.9515 3.31756C10.9528 3.27747 10.946 3.23753 10.9316 3.2001C10.8489 2.96608 10.6956 2.76347 10.493 2.62018C10.2903 2.47689 10.0482 2.39996 9.8 2.4ZM9.8 4.8V6C9.95913 6 10.1117 5.93679 10.2243 5.82426C10.3368 5.71174 10.4 5.55913 10.4 5.4C10.4 5.24087 10.3368 5.08826 10.2243 4.97574C10.1117 4.86321 9.95913 4.8 9.8 4.8Z"
          fill="#00CC9C"
        />
      </g>
      <defs>
        <clipPath id="clip0_4525_946742">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcHandsCoin;
