import React from "react";
import Modal from "./Modal";
import BankDetail from "./BankDetail";
import { IPortfolioRequest } from "@/types";
import { completeTransfer } from "@/services/api";

interface CompletePaymentModalProps {
  data: IPortfolioRequest;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  getAsset: (asset: string) => string;
}

const CompletePaymentModal = ({ data, isOpen, onClose, onConfirm, getAsset }: CompletePaymentModalProps) => {
  const amountToTransfer = data.tokenAmount * 500;
  const asset = data.projectId.assetIds[0]?.assetType as string;
  const imageAsset = getAsset(asset);

  const bankDetails = [
    {
      title: "Account Holder Name",
      value: data?.projectId?.companyId?.bankAccountHolderName,
    },
    {
      title: "Bank Name",
      value: data?.projectId?.companyId?.bankName,
    },
    {
      title: "Account Number",
      value: data?.projectId?.companyId?.bankAccountNumber,
      canCopy: true,
    },
    {
      title: "SWIFT/BIC Code",
      value: data?.projectId?.companyId?.bankSwiftBicCode,
      canCopy: true,
    },
    {
      title: "IBAN",
      value: data?.projectId?.companyId?.bankIban,
      canCopy: true,
    },
  ];

  // const completePayment = async () => {
  //   try {
  //     const reqBody = {
  //       transferType:"BankTransfer",
  //       iban: data?.projectId?.companyId?.bankIban || "",
  //       accountHolderName: data?.projectId?.companyId?.bankAccountHolderName || "",
  //       bankName: data?.projectId?.companyId?.bankName || "",
  //       accountNumber: data?.projectId?.companyId?.bankAccountNumber || "",
  //       SwiftCode: data?.projectId?.companyId?.bankSwiftBicCode || ""
  //     };
  //     const response = await completeTransfer(data._id, reqBody);
  //     if (response.code === 200) {
  //       // setIsSuccess(true);
  //       onConfirm();
  //     } else {
  //       // setIsSuccess(false);
  //     }

  //   } catch (error) {
  //     console.error("Error completing payment:", error);
  //     // Handle error (e.g., show error message to user)
  //   }
  // };

  return (
    <Modal isOpen={isOpen} title="Complete Payment" onClose={() => onClose()}>
      <div className="flex flex-col">
        <p className="text-[11px] font-normal text-white mb-2">Token</p>
        <div className="flex items-center justify-between px-3 py-2 border-[0.5px] border-monochrome-80 rounded-lg mb-4">
          <div className="flex gap-4">
            <img src={data.projectId.projectImage || imageAsset} alt="icon" className=" w-9 h-9 rounded-full object-cover" />
            <div>
              <p className="text-xs font-medium text-white leading-[18px]">{data.projectId.name}</p>
              <p className="text-[10px] font-normal text-monochrome-20 leading-[15px]">{data.projectId.projectDescription}</p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-2">
            <p className="text-white bg-monochrome-80 h-min w-fit rounded-[32px] px-2 py-[2px]">$500/token</p>
            <p className="text-primary-100 bg-primary-10 h-min w-fit rounded-[32px] px-2 py-[2px]">APY {10}%</p>
          </div>
        </div>

        <p className="text-[11px] font-normal text-white mb-2">Payment Amount</p>
        <div className="flex items-center justify-between px-3 py-2 border-[0.5px] border-monochrome-40 rounded-lg mb-4">
          <p className="text-white text-[26px] font-medium leading-[39px]">${amountToTransfer.toLocaleString()} USD</p>
          <p className="text-monochrome-20 text-xs font-normal leading-[18px]">{data.tokenAmount} tokens</p>
        </div>

        <p className="text-[11px] font-normal text-white mb-2">Bank Details</p>
        <BankDetail data={bankDetails} />

        <div className="w-full flex gap-4">
          <button
            className=" w-full px-6 py-2 rounded-lg bg-monochrome-60 border border-monochrome-40 text-white text-xs font-normal text-center"
            onClick={() => onClose()}
          >
            Close
          </button>
          {/* <button
            className=" w-full px-6 py-2 rounded-lg bg-monochrome-60 border border-monochrome-40 text-white text-xs font-normal text-center"
            onClick={onConfirm}
          >
            Complete Transfer
          </button> */}
        </div>
      </div>
    </Modal>
  );
};

export default CompletePaymentModal;
