import React from "react";

const IcGovernance = (props: any) => {
  return (
    <div {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.232 8.99997H13.5C13.6326 8.99997 13.7598 9.05265 13.8536 9.14642C13.9473 9.24019 14 9.36736 14 9.49997C14 9.63258 13.9473 9.75976 13.8536 9.85353C13.7598 9.94729 13.6326 9.99997 13.5 9.99997H6.5C6.36739 9.99997 6.24021 9.94729 6.14645 9.85353C6.05268 9.75976 6 9.63258 6 9.49997C6 9.36736 6.05268 9.24019 6.14645 9.14642C6.24021 9.05265 6.36739 8.99997 6.5 8.99997H7.257C7.11548 8.84298 7.02735 8.64518 7.00525 8.43497C6.98315 8.22477 7.02822 8.01296 7.134 7.82997L9.634 3.49997C9.76661 3.2703 9.98503 3.10271 10.2412 3.03407C10.4974 2.96544 10.7703 3.00137 11 3.13397L13.598 4.63397C13.8277 4.76658 13.9953 4.985 14.0639 5.24117C14.1325 5.49735 14.0966 5.77029 13.964 5.99997L12.232 8.99997ZM13.098 5.49997L10.5 3.99997L8 8.32997L9.16 8.99997H11.078L13.098 5.49997ZM13.96 7.99997L14.537 6.99997C14.686 7.00557 14.8318 7.04439 14.9638 7.1136C15.0958 7.1828 15.2107 7.28065 15.3 7.39997L17.8 10.733C17.9298 10.9061 18 11.1166 18 11.333V16C18 16.2652 17.8946 16.5195 17.7071 16.7071C17.5196 16.8946 17.2652 17 17 17H3C2.73478 17 2.48043 16.8946 2.29289 16.7071C2.10536 16.5195 2 16.2652 2 16V11.333C2 11.1166 2.07018 10.9061 2.2 10.733L4.7 7.39997C4.79315 7.27578 4.91393 7.17497 5.05279 7.10555C5.19164 7.03612 5.34475 6.99997 5.5 6.99997H6.463L5.984 7.82997C5.95275 7.88408 5.92663 7.94099 5.906 7.99997H5.5L3.25 11H16.75L14.5 7.99997H13.96ZM3 16H17V12H3V16Z"
          fill="#9E9E9E"
        />
      </svg>
    </div>
  );
};

export default IcGovernance;
