import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  exp: number;
}

export const isTokenExpired = (token: string, loginTime: number | null): boolean => {
  if (!token || !loginTime) return true;

  try {
    const decoded: DecodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decoded.exp < currentTime || currentTime - loginTime > decoded.exp; // Check if current time exceeds token expiration
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
};

export const getTokenExpiration = (token: string): number | null => {
  if (!token) return null;

  try {
    const decoded: DecodedToken = jwtDecode(token);
    return decoded.exp * 1000; // convert to milliseconds
  } catch (error) {
    console.error("Error decoding token:", error);
    return null;
  }
};
