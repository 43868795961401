import React from "react";

const FillPortfolio = (props: any) => {
  return (
    <div {...props}>
      <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.16663 12.9165V14.4165C3.16663 15.3498 3.16663 15.8165 3.34829 16.1732C3.50808 16.4868 3.76304 16.7417 4.07663 16.9015C4.43246 17.0832 4.89913 17.0832 5.83079 17.0832H18.1666M3.16663 12.9165V2.9165M3.16663 12.9165L6.38079 10.239C7.74246 9.104 8.63746 9.22067 9.81329 10.3965L9.81829 10.4015C11.1 11.6832 12.0183 11.6298 13.295 10.5123L18.1666 6.24984"
          stroke="#00CC9C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default FillPortfolio;
