import React from "react";

const FillBuyPresaleToken = (props: any) => {
  return (
    <div {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.694458 9.81152C0.694458 6.19516 3.62355 3.26607 7.23991 3.26607C10.8563 3.26607 13.7854 6.19516 13.7854 9.81152C13.7854 13.4279 10.8563 16.357 7.23991 16.357C3.62355 16.357 0.694458 13.4279 0.694458 9.81152ZM18.6945 4.08425L16.4445 3.06152L15.4217 0.811523L14.399 3.06152L12.149 4.08425L14.399 5.10698L15.4217 7.35698L16.4445 5.10698L18.6945 4.08425ZM18.6945 15.5388L16.4445 14.5161L15.4217 12.2661L14.399 14.5161L12.149 15.5388L14.399 16.5615L15.4217 18.8115L16.4445 16.5615L18.6945 15.5388ZM7.24105 14.9246C10.0643 14.9246 12.3531 12.6359 12.3531 9.81264C12.3531 6.98936 10.0643 4.70064 7.24105 4.70064C4.41777 4.70064 2.12905 6.98936 2.12905 9.81264C2.12905 12.6359 4.41777 14.9246 7.24105 14.9246Z"
          fill="#9E9E9E"
        />
        <path d="M8.02779 9.03178H9.69446V7.87793H4.69446V9.03178H6.36112V12.8779H8.02779V9.03178Z" fill="#9E9E9E" />
      </svg>
    </div>
  );
};

export default FillBuyPresaleToken;
