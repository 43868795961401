import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { AuthContext } from "@/provider/AuthContext";
import useStore from "@store/useStore";
import { http } from "@services/http";
import { ENDPOINT } from "@constants";
import ContactSupport from "./Page/ContactSupport/ContactSupport";
import Loader from "./Loader";
import Button from "./UI/Button";
import IcCross from "@/assets/ic_cross";
import { Modal } from "flowbite-react";

interface ContactSupportModalProps {
  open: boolean;
  onClose: (isSuccess: boolean) => void;
}

const ContactSupportModal = ({ open, onClose }: ContactSupportModalProps) => {
  const { authToken } = useContext(AuthContext);
  // const [topic, setTopic] = useState("");
  // const [message, setMessage] = useState("");

  const [contactSupport, setContactSupport] = useState({
    topic: '',
    message: '',
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { userData: userDetails } = useStore();
  

  const handleOnChange = (field: string, value: string) => {
    setContactSupport({ ...contactSupport, [field]: value });
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!contactSupport.topic || !contactSupport.message) {
      toast.error("Both topic and message are required.");
      return;
    }
    setIsSubmitting(true);

    const payload = {
      topic: contactSupport.topic,
      message: contactSupport.message,
      userDetails,
      authToken,
    };

    try {
      await http.post(ENDPOINT.CONTACT_SUPPORT, payload);
      toast.success("Support request mail sent. We will reach out to you shortly.");
      resetForm();
      onClose(true);
    } catch (error) {
      toast.error("Failed to submit support request. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setContactSupport({ topic: '', message: '' });
  }

  return (
    <Modal show={open} onClose={()=>{onClose(false)}} position={'center'} size={'2xl'}>
        <Modal.Body className="p-6 bg-monochrome-100">
          <div className={"flex justify-between items-center pb-5"}>
            <h2 className="mb-0 font-semibold">
              Contact Support
            </h2>
            <Button classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" rounded
              onClick={()=>{onClose(false)}}>
              <IcCross />
            </Button>
          </div>
          <hr className="border-monochrome-40"/>
          <section className="flex flex-col lg:flex-row rounded divide-y lg:divide-y-0 divide-x-0 lg:divide-x divide-monochrome-10 pt-5">
          <form className="flex flex-col body-small-regular gap-4 !pt-0 mobile-space  w-full" onSubmit={handleSubmit} >
              <div className="flex flex-col gap-2">
              <h6 className="text-white">Have Any Questions ?</h6>
                {/* <p className="body-small-regular text-monochrome-30 mt-2 mb-2">Please send a mail to <a className="!text-green-500" href="mailto:support@penomo.io">hello@penomo.io</a></p> */}
                <p className="body-small-regular text-[monochrome-30]">Submit this form to send a mail to: <a className="!text-[#00CC9C]" href="mailto:support@penomo.io">hello@penomo.io</a></p>
              </div>
              <div className="flex flex-col">
                <label className="text-black mb-1 text-white">Topic</label>
                <input type="text" placeholder="Enter your topic title here" className="w-full !m-0 !border-monochrome-10 !rounded-lg" value={contactSupport.topic} onChange={(e) => handleOnChange('topic', e.target.value)} />
              </div>
              <div className="flex flex-col">
                <label className="text-black mb-1 text-white">Message</label>
                <textarea placeholder="Please describe your issue or inquiry" rows={6} className="w-full !m-0 !border-monochrome-10 !rounded-lg" value={contactSupport.message} onChange={(e) => handleOnChange('message', e.target.value)} />
              </div>
              {userDetails ? <p className="text-monochrome-30">Our response will be sent to your email, <a className="text-green-500" href={`mailto:${userDetails.email}`}>{userDetails.email}</a></p> : "Loading ..."}
              <Button type="submit" classNames="w-[120px] text-black hover:text-white" secondary rounded disabled={isSubmitting}>
                {
                  isSubmitting ? <Loader data-testid="loader" isLoading={true} /> : "Submit"
                }
              </Button>
              {/* <Button type="button" primary rounded onClick={() => { onClose(false) }}>Close</Button> */}
            </form>
          </section>
        </Modal.Body>
      </Modal>
  );
};

export default ContactSupportModal;
