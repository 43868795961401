import React from "react";
import Button from "@/components/UI/Button";
import successImage from "@/assets/success.png"; // Adjust the import as needed for the success image
import infoImage from "@/assets/info.png"; // Adjust the import as needed for the info image
import { useNavigate } from "react-router-dom";

interface InvestPageThreeProps {
  handleClose: () => void;
  success: boolean;
}

const InvestPageThree: React.FC<InvestPageThreeProps> = ({ handleClose, success }) => {
  const navigate = useNavigate();
  return (
    <div className="mt-6 flex flex-col justify-center items-center text-center space-y-6">
      {/* Here we use the correct image path based on the success boolean */}
      <img src={success ? successImage : infoImage} className="size-12" alt={success ? "Success" : "Info"} />
      <div className="space-y-2">
        <h3>{success ? "Purchase Request has been submitted" : "Error sending purchase request"}</h3>
        <p className="text-monochrome-20">We will get back to you</p>
      </div>
      <div className="grid grid-cols-2 items-center gap-4 w-full">
        <Button onClick={handleClose} classNames="h-[37px] bg-monochrome-60 text-white w-full rounded-lg hover:text-monochrome-20 transition font-bold">
          Close
        </Button>
        <button className="btn btn-primary w-full" onClick={() => navigate("/portfolio?tab=purchaseRequest")}>
          View Portfolio
        </button>
      </div>
    </div>
  );
};

export default InvestPageThree;
