import React from 'react'
interface IconProps {
    className?: string;
  }
  
  const IcBuy: React.FC<IconProps> = ({ className }) => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
            <rect x="0.506714" width="16" height="16" rx="8" fill="#243C3F" />
            <path d="M11.3351 5.17171L5.67834 10.8285" stroke="#00CC9C" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.93229 5.33182L11.335 5.1714L11.1749 9.57446" stroke="#00CC9C" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default IcBuy