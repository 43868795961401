import React from "react";
import Modal from "./Modal";
import { IPortfolioRequest } from "@/types";

interface ResolvePaymentModalProps {
  data: IPortfolioRequest;
  isOpen: boolean;
  onClose: () => void;
  onPay: () => void;
  onRefund: () => void;
  getAsset: (asset: string) => string;
}

const ResolvePaymentModal = ({ data, isOpen, onClose, onPay, onRefund, getAsset }: ResolvePaymentModalProps) => {
  const remainingAmount = data.transactionDetails?.remaingAmountToPay || 0;
  const amountToTransfer = data.tokenAmount * 500;
  const amount = remainingAmount > 0 ? remainingAmount : amountToTransfer;

  const asset = data.projectId.assetIds[0]?.assetType as string;
  const imageAsset = getAsset(asset);

  const paymentSummary = [
    { title: "Total Amount", value: amountToTransfer },
    { title: "Amount Paid", value: data.transactionDetails?.amountTransferredByInvestor || 0 },
    { title: "Remaining Amount", value: remainingAmount },
  ];

  return (
    <Modal isOpen={isOpen} title="Resolve Payment Issue" onClose={() => onClose()}>
      <div className="flex flex-col">
        <p className="text-[11px] font-normal text-white mb-2">Token</p>
        <div className="flex items-center justify-between px-3 py-2 border-[0.5px] border-monochrome-80 rounded-lg mb-4">
          <div className="flex gap-4">
            <img src={data.projectId.projectImage || imageAsset} alt="icon" className=" w-9 h-9 rounded-full object-cover" />
            <div>
              <p className="text-xs font-medium text-white leading-[18px]">{data.projectId.name}</p>
              <p className="text-[10px] font-normal text-monochrome-20 leading-[15px]">{data.projectId.projectDescription}</p>
            </div>
          </div>
          <div className="flex gap-2 flex-col md:flex-row">
            <p className="text-white bg-monochrome-80 h-min w-fit rounded-[32px] px-2 py-[2px]">$500/token</p>
            <p className="text-primary-100 bg-primary-10 h-min w-fit rounded-[32px] px-2 py-[2px]">APY {10}%</p>
          </div>
        </div>

        <p className="text-[11px] font-normal text-white mb-2">Payment Amount</p>
        <div className="flex items-center justify-between px-3 py-2 border-[0.5px] border-monochrome-40 rounded-lg mb-4">
          <p className="text-white text-[26px] font-medium leading-[39px]">${amount.toLocaleString()} USD</p>
          <p className="text-monochrome-20 text-xs font-normal leading-[18px]">{data.tokenAmount} tokens</p>
        </div>

        <p className="text-[11px] font-normal text-white mb-2">Payment Amount</p>
        <div className="flex flex-col gap-4 p-4 border-[0.5px] border-monochrome-80 bg-monochrome-150 rounded-lg mb-4">
          {paymentSummary.map((item, index) => (
            <div key={index} className="flex justify-between">
              <p className="text-monochrome-20 text-xs font-normal leading-[18px]">{item.title}</p>
              <p className="text-white text-sm font-medium leading-[21px] text-right">${item.value.toLocaleString()}</p>
            </div>
          ))}
        </div>

        <div className="w-full flex gap-4">
          <button className=" w-full px-6 py-2 rounded-lg bg-primary-100 text-monochrome-100 text-xs font-normal text-center" onClick={() => onPay()}>
            Pay Remaining Amount
          </button>
          <button
            className=" w-full px-6 py-2 rounded-lg bg-monochrome-60 border border-monochrome-40 text-white text-xs font-normal text-center"
            onClick={() => onRefund()}
          >
            Request Refund
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ResolvePaymentModal;
