const TokenSale = () => {
  return (
    <div className="transactions-page">
      <h1 className="page-header">Token Sale</h1>
      <div className="page-container md:!mt-10">
        <iframe
          src="https://tokentool.bitbond.com/tokensale/0x802D5dB8799CD236fd2771c715083BfbcD8e25Ae?chainId=11155111"
          width="100%"
          height="1000px"
          frameBorder="0"
          name="tokensale"
          title="Token Sale"
        ></iframe>
      </div>
    </div>
  );
};

export default TokenSale;
