const IcPortfolio = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 12.917V14.417C2.5 15.3503 2.5 15.817 2.68167 16.1737C2.84145 16.4872 3.09641 16.7422 3.41 16.902C3.76583 17.0837 4.2325 17.0837 5.16417 17.0837H17.5M2.5 12.917V2.91699M2.5 12.917L5.71417 10.2395C7.07583 9.10449 7.97083 9.22116 9.14667 10.397L9.15167 10.402C10.4333 11.6837 11.3517 11.6303 12.6283 10.5128L17.5 6.25033"
        stroke="#9E9E9E"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IcPortfolio;
