const IcEdit = () => {
    return (
        <svg width="30" height="29" viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.94748 6.9528C9.03036 6.84259 9.18682 6.82028 9.29722 6.90296L13.6138 10.1366C13.7244 10.2194 13.7468 10.3763 13.6639 10.4868L9.64747 15.8367C8.68079 17.1284 7.22246 17.3867 6.21412 17.3867C5.58912 17.3867 5.13912 17.2867 5.08912 17.2784C4.98079 17.2534 4.88079 17.1784 4.82246 17.0784C4.76412 16.97 3.33079 14.42 4.93079 12.295L8.94748 6.9528Z" fill="currentColor" />
            <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M15.2722 8.3451L14.7138 9.08735C14.6309 9.1976 14.4745 9.21985 14.3642 9.13718L10.0468 5.903C9.93649 5.82035 9.91382 5.66399 9.99624 5.55348L10.5555 4.80345C11.1305 4.02845 12.0222 3.62012 12.9222 3.62012C13.5388 3.62012 14.1555 3.81178 14.6888 4.21178C15.3138 4.68678 15.7222 5.37845 15.8388 6.15345C15.9472 6.93678 15.7472 7.71178 15.2722 8.3451Z" fill="currentColor" />
            <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M17.482 16.1265H12.1678C11.8228 16.1265 11.5428 16.4065 11.5428 16.7515C11.5428 17.0965 11.8228 17.3765 12.1678 17.3765H17.482C17.827 17.3765 18.107 17.0965 18.107 16.7515C18.107 16.4065 17.827 16.1265 17.482 16.1265Z" fill="currentColor" />
        </svg>
    )
}

export default IcEdit