import React from 'react';

interface KycStatusProps {
  status: 'Not Submitted' | 'Pending' | 'Verified';
}

const KycStatus: React.FC<KycStatusProps> = ({ status }) => {
  const getStatusStyles = () => {
    switch (status) {
      case 'Not Submitted':
        return 'text-red-500';
      case 'Pending':
        return 'text-yellow-500';
      case 'Verified':
        return 'text-green-500';
      default:
        return '';
    }
  };

  return (
    <div className={`flex items-center gap-2 ${getStatusStyles()}`}>
      <span className="font-semibold text-[0.875rem]">{status}</span>
    </div>
  );
};

export default KycStatus;
