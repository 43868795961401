import { Modal } from "flowbite-react";
import Button from "@/components/UI/Button";
import React from "react";
import successImage from "@/assets/success.png"; // Adjust the import as needed for the success image
import infoImage from "@/assets/info.png";     // Adjust the import as needed for the info image
import IcCross from "@/assets/ic_cross";

interface TransferResultModalProps {
  show: boolean;
  onClose: () => void;
  success: boolean;
}

const TransferResultModal: React.FC<TransferResultModalProps> = ({ show, onClose, success }) => {
  return (
    <Modal show={show} onClose={onClose} position="center" size="xl">
      <Modal.Body className="p-4 bg-monochrome-100">
      <div className="flex justify-between items-center pb-5">
          <h1 className="mb-0">Transaction Details</h1>
          <Button classNames="w-[32px] bg-monochrome-60 border border-monochrome-40 !px-2 !rounded-xl" onClick={onClose}>
            <IcCross />
          </Button>
        </div>
        <hr className="border-monochrome-40" />
        <div className="my-6 flex flex-col justify-center items-center text-center space-y-6">
      {/* Here we use the correct image path based on the success boolean */}
      <img src={success ? successImage : infoImage} alt={success ? "Success" : "Info"} className="mx-auto mb-4" />
      <h3 className="text-lg font-bold">{success ? "Payment Successful!" : "Payment Failed"}</h3>
        <p className="text-white mt-2">
          {success ? "Your payment has been successfully processed." : "There was an issue processing your payment. Please try again later."}
        </p>
      </div>
        
        <div className="flex justify-center mt-4">
          <Button classNames="btn btn-primary w-full" onClick={onClose}>
            OK
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TransferResultModal;
