import React from "react";
import type { CustomFlowbiteTheme } from "flowbite-react";
import { Modal } from "flowbite-react";
import IcCross from "@/assets/ic_cross";

interface TheModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title: string;
  modalSize?: {
    [key: string]: string;
  };
}

const TheModal = ({ children, isOpen, onClose, title, modalSize }: TheModalProps) => {
  const customTheme: CustomFlowbiteTheme["modal"] = {
    root: {
      sizes: {
        "534": "w-[534px]",
        ...modalSize,
      },
    },
    content: {
      base: "rounded-lg bg-monochrome-100",
      inner: "p-4",
    },
    body: {
      base: "rounded-lg",
    },
  };

  return (
    <Modal show={isOpen} onClose={() => onClose()} size={"534"} popup theme={customTheme}>
      <Modal.Body>
        <div className=" flex justify-between items-center mb-4 pb-4 border-b-[0.5px] border-monochrome-40">
          <h3 className=" font-semibold text-xl leading-[30px]">{title}</h3>
          <button onClick={onClose} className=" rounded-lg border border-monochrome-40 p-1">
            <IcCross className="text-white p-" />
          </button>
        </div>
        {children}
      </Modal.Body>
    </Modal>
  );
};

export default TheModal;
