import React, { useState } from 'react';
import './Notifications.css';
import useAuth from '@/hooks/useAuth';
import Button from '@/components/UI/Button';
import { useQuery } from '@tanstack/react-query';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchAllNotifications, readNotification, readAllNotifications } from '@/services/api';
import Loader from '@/components/Loader';
import { Modal } from 'flowbite-react';
import useStore from '@/store/useStore';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';

type Notification = {
    _id: number;
    icon: string;
    color: string;
    status?: string;
    createdAt: string;
    messages: string;
    linkText?: string;
};

type NotificationsType = {
    [key: string]: Notification[];
};

const Notifications: React.FC = () => {
    const { userData } = useStore();
    const navigate = useNavigate();
    const id = userData?._id || userData?.companyId;
    
    const [deleteLoading, setDeleteLoading]:any = useState(false);

    const { data: notification = [], isLoading: isLoadingUser, refetch } = useQuery({
        queryKey: ["getNotifications", id],
        queryFn: () => fetchAllNotifications(id),
        enabled: !!id,
        select: (data: any) => data.data
    });

    const sortedNotifications = notification.sort((a: any, b: any) => {
        return new Date(b.updatedAt || b.createdAt).getTime() - new Date(a.updatedAt || a.createdAt).getTime();
    });

    const allNotifications = sortedNotifications.map((item: any) => ({
        ...item,
        icon: "bi bi-coin",
        color: "#1DD05D"
    }));

    const mostRecent = [...allNotifications].slice(0, 4);

    const earlier = allNotifications.filter((item: any) => !mostRecent.some((rect: any) => rect._id === item._id));

    const notifications: NotificationsType = {
        "All Notifications": allNotifications,
        "Most Recent": mostRecent,
        "Earlier": earlier
    };

    const handleDeleteNotification = async (_id: string) => {
        setDeleteLoading(true);
        await readNotification(_id.toString());
        await refetch();
        setDeleteLoading(false);
    };

    const handleNavigation = (path: string) => {
        navigate(path);
    };

    const handleClearAll = async () => {
        setDeleteLoading(true);
        await readAllNotifications(id);
        await refetch();
        setDeleteLoading(false);
    };

    return (
        <>
            {(isLoadingUser || deleteLoading) && (
                <div className="min-h-[40px] loader-center">
                    <Loader isLoading={true} svgStyle={{ width: "2.5rem", height: "2.5rem" }} />
                </div>
            )}
            {Object.keys(notifications).map((item: string, index: number) => {
                return (
                    <Container className="notifications-container" key={index}>
                        <div className="flex justify-between">
                            <h2 className="mb-0">{item}</h2>
                            {item === "All Notifications" && allNotifications.length > 0 && (
                                <Button
                                    secondary
                                    rounded
                                    classNames="text-xs"
                                    onClick={handleClearAll}
                                >
                                    Clear All
                                </Button>
                            )}
                        </div>
                        {notifications[item].map((notification:any) => (
                            <Row key={notification._id} className="notification">
                                <Col xs={1} className="notification-icon" style={{ color: notification.color, width: "fit-content" }}>
                                    <i className={notification.icon}></i>
                                </Col>
                                <Col xs={11} className="content">
                                    <div className="notification-title-wrapper">
                                        <p>
                                            {notification.messages}
                                            {notification?.linkText && <a href="#">{notification?.linkText}</a>}
                                        </p>
                                        <p className="time">{dayjs(notification.createdAt).format("DD/MM/YYYY")}</p>
                                    </div>
                                    <div className={`flex notification-action-container ${notification.status === "Complete KYC" || notification.status === "Complete KYB" ? "justify-between" : "justify-end"}`}>
                                        {(notification.status === "Complete KYC" || notification.status === "Complete KYB") && (
                                            <Button
                                                secondary
                                                rounded
                                                classNames="bg-monochrome-60"
                                                onClick={() => handleNavigation('/settings')}
                                            >
                                                {notification.status}
                                            </Button>
                                        )}
                                        <div className="cursor-pointer" onClick={() => handleDeleteNotification(notification._id)}>X</div>
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </Container>
                );
            })}
        </>
    );
};

export default Notifications;
