//Vijay's Work
// import { useEffect, useState } from "react";
// import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
// import Info from "@/components/Page/Campaign/Info";
// import Leaderboard from "@/components/Page/Campaign/Leaderboard";
// import Quest from "@/components/Page/Campaign/Quest";

// const CampaignPage = () => {
//   const tabList = ["Info", "Quests", "Leaderboard"];
//   const [selectedTab, setSelectedTab] = useState(0);
//   const [searchParams, setSearchParams] = useSearchParams();
//   const navigate = useNavigate();

//   // Get the section from URL params
//   const section = searchParams.get("section")?.toLowerCase();
//   const twitterState = searchParams.get("state");

//   const { hash } = useLocation();
//   const telegramHash = hash?.split("=")[1]

//   useEffect(() => {
//     // Set initial tab based on URL params
//     if (section) {
//       switch (section) {
//         case "info":
//           setSelectedTab(0);
//           break;
//         case "quests":
//           setSelectedTab(1);
//           break;
//         case "leaderboard":
//           setSelectedTab(2);
//           break;
//         default:
//           setSelectedTab(0);
//       }
//     }
//   }, [section]);

//   useEffect(() => {
//     if (twitterState || telegramHash) {
//       setSelectedTab(1);
//     }
//   }, [twitterState, telegramHash]);

//   const handleTabChange = (index: number) => {
//     setSelectedTab(index);
//     // Update URL when tab changes
//     const sectionName = tabList[index].toLowerCase();
//     setSearchParams({ section: sectionName });
//   };

//   return (
//     <div className="w-full">
//       <div className="text-sm font-medium text-center text-gray-500 border-b border-green-30">
//         <ul className="flex flex-wrap -mb-px">
//           {tabList.map((item: string, index: number) => (
//             <li
//               key={index}
//               className={`cursor-pointer ${selectedTab === index ? "text-green-500 border-b border-green-500" : "text-green-50"}`}
//               onClick={() => handleTabChange(index)}
//             >
//               <span className="inline-block px-8 py-3 border-b-2 border-transparent rounded-t-lg">{item}</span>
//             </li>
//           ))}
//         </ul>
//       </div>
//       {selectedTab === 0 && <Info setSelectedTab={setSelectedTab} />}
//       {selectedTab === 1 && <Quest />}
//       {selectedTab === 2 && <Leaderboard />}
//     </div>
//   );
// };

// export default CampaignPage;

// import React from "react";

// const CampaignPage = () => {
//   return (
//     <div className="bg-monochrome-100 ml-4 mr-4 flex justify-center items-center rounded-lg mb-0 h-[calc(100vh-265px)] lg:h-[calc(100vh-175px)]">
//       <div className="lg:w-1/2 flex flex-col items-center">
//         <p className="text-center text-lg">Coming Soon.</p>
//       </div>
//     </div>
//   );
// };

// export default CampaignPage;

import { useEffect, useState } from "react";
import Info from "@/components/Page/Campaign/Info";

const CampaignPage = () => {
  return (
    <div className="w-full">
      <div className="text-sm font-medium text-center text-gray-500 border-b border-green-30">
        <ul className="flex flex-wrap -mb-px">
          <li className="text-green-500 border-b border-green-500">
            <span className="inline-block px-8 py-3 border-b-2 border-transparent rounded-t-lg">
              Info
            </span>
          </li>
        </ul>
      </div>
      <Info />
    </div>
  );
};

export default CampaignPage;
