const IcArrowUpSort = () => {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M6.90002 10.625V2.67493H6.15002V10.625H6.90002Z" fill="#9E9E9E" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53617 6.30518L6.52527 3.28128L3.51391 6.30518L2.98248 5.77598L6.52532 2.21844L10.0682 5.77593L9.53617 6.30518Z"
        fill="#9E9E9E"
      />
    </svg>
  );
};

export default IcArrowUpSort;
