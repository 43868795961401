import { IInvestor, IUser } from "@/types";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface IStore {
  userData: IUser;
  investorData: IInvestor;
  setUser: (userData: IUser) => void;
  setInvestor: (by: IInvestor) => void;
  reset: () => void;
}

const initialState = {
  userData: {
    _id: "",
    isProfileExisting: false,
    walletAddress: null,
    emailNotifications: false,
    investorId: "",
    role: "",
    permissions: [],
    kycStatus: "Not Submitted",
    statusUpdates: [],
    isDeleted: false,
    deletedAt: null,
    personalIdentityDocuments: [],
    pepHits: [],
    sanctionHits: [],
    __v: 0,
    email: "",
    firstName: "",
    lastName: "",
  },
  investorData: {
    _id: "",
    investorType: "",
    financialKnowledge: "",
    kybStatus: "Not Submitted",
    signingCombinations: [],
    boardMembers: [],
    statusUpdates: [],
    deletedAt: null,
    isVerifiedAccredited:false,
    jurisdictionViewCount: [],
    organizationDocuments: [],
    legalDocuments: [],
    complianceDocuments: [],
    boardMemberDocuments: [],
    __v: 0,
    residencyCountry: null,
    bankAccountHolderName: "",
    bankName: "",
    bankAccountNumber: "",
    bankSwiftBicCode: "",
    bankIban: "",
    bankDefaultCurrency: "",
  },
};

const useStore = create<IStore>()(
  persist(
    (set) => ({
      ...initialState,
      setUser: (userData) => set((state) => ({ ...state, userData })),
      setInvestor: (investorData) => set((state) => ({ ...state, investorData })),
      reset: () => set(initialState),
    }),
    { name: "penomo_db" }
  )
);

export default useStore;
