const IcSwap = () => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.4198 10.5818V3.77295" stroke="#9E9E9E" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.4586 8.53369L8.41975 10.582L6.38086 8.53369" stroke="#9E9E9E" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.45557 2.4165V9.22539" stroke="#9E9E9E" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1.41675 4.46435L3.45564 2.41602L5.49453 4.46435" stroke="#9E9E9E" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default IcSwap;
