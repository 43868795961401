import React from "react";
import Modal from "./Modal";
import successImage from "@/assets/success.png";
import { IPortfolioRequest } from "@/types";

interface PaymentCheckModalProps {
  data: IPortfolioRequest;
  isOpen: boolean;
  onClose: () => void;
}

const PaymentCheckModal = ({ data, isOpen, onClose }: PaymentCheckModalProps) => {
  const remainingAmount = data.transactionDetails?.remaingAmountToPay || 0;
  const amountToTransfer = data.tokenAmount * 500;
  const amount = remainingAmount > 0 ? remainingAmount : amountToTransfer;

  return (
    <Modal isOpen={isOpen} title="Payment Check" onClose={() => onClose()}>
      <div className="flex flex-col">
        <div className="w-full justify-center items-center flex flex-col text-center mb-8">
          <img src={successImage} alt="success" className="mb-4" />
          <h3 className="text-[18px] font-medium leading-[27px] text-white mb-[2px]">Payment Confirmation Submitted</h3>
          <p className="text-[11px] font-normal leading-[11.5px] text-monochrome-20">
            Your payment of ${amount.toLocaleString()} has been submitted for verification.
          </p>
        </div>

        <div className="w-full flex gap-4">
          <button
            className=" w-full px-6 py-2 rounded-lg bg-monochrome-60 border border-monochrome-40 text-white text-xs font-normal text-center"
            onClick={() => onClose()}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentCheckModal;
