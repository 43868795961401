const IcArrowUpFill = (props: any) => {
    return (<div {...props}>
         <svg width="11" height="11" viewBox="0 0 11 11" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.66166 2.67159L3.00488 8.32837" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.25882 2.83176L8.66154 2.67134L8.50146 7.0744" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        </div>)
}

export default IcArrowUpFill
