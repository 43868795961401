// import React, { useState, useContext } from "react";
// import { useLocation } from "react-router-dom";
// import { Modal } from "flowbite-react";
// import { ROUTES } from "@/constants";
// import { NavBarList } from "@/constants/NavMenu";
// import useAuth from "@/hooks/useAuth";
// import { useNetwork } from "@/provider/NetworkContext";
// import { classNames } from "@/utils";
// import "./Header.css";
// import Notifications from "./Notifications";
// import Button from "./UI/Button";
// import Dropdown from "./UI/Dropdown/Dropdown";
// import { IcChecked, IcWarning } from "@/assets";
// import { AuthContext } from "@/provider/AuthContext";

// const networkOptions = [
//   {
//     value: "polygon",
//     label: "Polygon",
//     icon: "./assets/ic_polygon.svg",
//   },
//   {
//     value: "peaq",
//     label: "PEAQ",
//     icon: "./assets/ic_peaq.svg",
//   },
// ];

// const Header: React.FC = () => {
//   const { pathname } = useLocation();
//   const { userId } = useAuth();
//   const { selectedNetwork, setSelectedNetwork } = useNetwork();
//   const { switchNetwork } = useContext(AuthContext);
//   const [showConfirmationModal, setShowConfirmationModal] = useState(false);
//   const [showSuccessModal, setShowSuccessModal] = useState(false);
//   const [pendingNetwork, setPendingNetwork] = useState<string>("");

//   const isSetupProfilePage = pathname === ROUTES.PROFILE_SETUP;
//   const isMarketplaceDetailsPage = pathname === ROUTES.MARKETPLACEDETAILS;
//   const isCampaignPage = pathname === ROUTES.CAMPAIGN;

//   const handleNetworkChange = (network: string) => {
//     setPendingNetwork(network);
//     setShowConfirmationModal(true);
//   };

//   const confirmNetworkSwitch = async () => {
//     setShowConfirmationModal(false);
//     try {
//       await switchNetwork();
//       setSelectedNetwork(pendingNetwork); // This will now persist the network in localStorage
//       setShowSuccessModal(true);
//     } catch (error) {
//       console.error("Failed to switch network:", error);
//       // Handle error (e.g., show error modal)
//     }
//   };

//   if (userId && isSetupProfilePage) {
//     return null;
//   }

//   return (
//     <header
//       className={classNames(
//         `mt-1 sticky-header-custom px-4 py-4 flex flex-row items-center justify-between gap-4 sticky top-0 z-20 ${
//           isCampaignPage ? "bg-monochrome-60" : "bg-monochrome-60"
//         }`,
//         { hidden: !userId }
//       )}
//     >
//       <h1 className="mb-0">
//         {NavBarList.find((item: any) => item.link === pathname)?.title}
//         {isMarketplaceDetailsPage && (
//           <div className="flex items-center" style={{ gap: "16px" }}>
//             <Button
//               rounded
//               classNames="bg-monochrome-100 rounded-lg aspect-square !p-3"
//               onClick={() => window.history.back()}
//             >
//               <svg
//                 width="17"
//                 height="17"
//                 viewBox="0 0 17 17"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fillRule="evenodd"
//                   clipRule="evenodd"
//                   d="M10.8855 3.94281L5.27606 9.55225L4.33325 8.60945L9.94272 3L10.8855 3.94281Z"
//                   fill="white"
//                 />
//                 <path
//                   opacity="0.4"
//                   fillRule="evenodd"
//                   clipRule="evenodd"
//                   d="M9.94272 14.219L4.33325 8.60955L5.27606 7.66675L10.8855 13.2762L9.94272 14.219Z"
//                   fill="white"
//                 />
//               </svg>
//             </Button>
//             Go back
//           </div>
//         )}
//       </h1>

//       <div className="flex flex-row gap-x-2 items-center sm:gap-y-4">
//         <div className="flex items-center gap-x-6 pr-2">
//           <Dropdown
//             icon={<img src={selectedNetwork === "polygon" ? networkOptions[0].icon : networkOptions[1].icon} alt="network icon" />}
//             value={selectedNetwork}
//             className="w-full md:w-[200px] h-[38px] bg-erieblack rounded"
//             onChange={(selectedValue: any) => handleNetworkChange(selectedValue.value)}
//             options={networkOptions}
//           />
//           <Notifications />
//         </div>
//       </div>

//       {/* Confirmation Modal */}
//       <Modal show={showConfirmationModal} size="2xl" onClose={() => setShowConfirmationModal(false)}>
//         <Modal.Header className="bg-monochrome-100">
//           <h3 className="text-white">Switch Network</h3>
//         </Modal.Header>
//         <hr />
//         <Modal.Body className="bg-monochrome-100">
//           <div className="flex justify-center p-4">
//             <IcWarning className="text-center" />
//           </div>
//           <div className="text-white text-center text-base">
//             Are you sure you want to switch to <span className="text-violet-400">{pendingNetwork}</span> network?
//           </div>

//           <div className="flex justify-center items-center mt-8 gap-4">
//             <Button secondary classNames="w-1/3 rounded-lg" onClick={() => setShowConfirmationModal(false)}>
//               No
//             </Button>
//             <Button secondary classNames="w-1/3 rounded-lg" onClick={confirmNetworkSwitch}>
//               Yes
//             </Button>
//           </div>
//         </Modal.Body>
//       </Modal>

//       {/* Success Modal */}
//       <Modal show={showSuccessModal} size="2xl" onClose={() => setShowSuccessModal(false)}>
//         <Modal.Header className="bg-monochrome-100">
//           <h3 className="text-white">Network Switch Successful</h3>
//         </Modal.Header>
//         <Modal.Body className="bg-monochrome-100 ">
//           <div className="flex justify-center p-4">
//             <div className="w-14 h-14 flex justify-center items-center">
//               <IcChecked />
//             </div>
//           </div>
//           <div className="flex items-center text-white justify-center text-base">
//             You have successfully switched to &nbsp; <span className="text-violet-400">{selectedNetwork}</span> &nbsp; network.
//           </div>
//           <div className="flex justify-center mt-8">
//             <Button secondary classNames="w-1/2" onClick={() => setShowSuccessModal(false)}>
//               Close
//             </Button>
//           </div>
//         </Modal.Body>
//       </Modal>
//     </header>
//   );
// };

// export default Header;

// import React, { useState, useContext } from "react";
// import { useLocation } from "react-router-dom";
// import { toast } from "react-hot-toast"; // Import react-hot-toast
// import { ROUTES } from "@/constants";
// import { NavBarList } from "@/constants/NavMenu";
// import useAuth from "@/hooks/useAuth";
// import { useNetwork } from "@/provider/NetworkContext";
// import { classNames } from "@/utils";
// import "./Header.css";
// import Notifications from "./Notifications";
// import Button from "./UI/Button";
// import Dropdown from "./UI/Dropdown/Dropdown";
// import { AuthContext } from "@/provider/AuthContext";

// const networkOptions = [
//   {
//     value: "polygon",
//     label: "Polygon",
//     icon: "./assets/ic_polygon.svg",
//   },
//   {
//     value: "peaq",
//     label: "PEAQ",
//     icon: "./assets/ic_peaq.svg",
//   },
// ];

// const Header: React.FC = () => {
//   const { pathname } = useLocation();
//   const { userId } = useAuth();
//   const { selectedNetwork, setSelectedNetwork } = useNetwork();
//   const { switchNetwork } = useContext(AuthContext);
//   const [pendingNetwork, setPendingNetwork] = useState<string>("");

//   const isSetupProfilePage = pathname === ROUTES.PROFILE_SETUP;
//   const isMarketplaceDetailsPage = pathname === ROUTES.MARKETPLACEDETAILS;
//   const isCampaignPage = pathname === ROUTES.CAMPAIGN;

//   const handleNetworkChange = async (network: string) => {
//     setPendingNetwork(network);
//     try {
//       await switchNetwork(); // Switching the network
//       setSelectedNetwork(network); // Set the new selected network

//       // Show large toast with close button
//       toast(
//         (t) => (
//           <div className="p-4 w-full text-base flex justify-between ">
//             <span>Network switched to <strong>{network}</strong> successfully!</span>
//             <button
//               onClick={() => toast.dismiss(t.id)} // Dismiss toast when "X" is clicked
//               className="ml-4 text-white px-3 py-1 rounded-lg"
//             >
//               X
//             </button>
//           </div>
//         ),
//         {
//           duration: Infinity, // Make the toast persistent
//           position: "top-right", // Position of the toast
//           style: {
//             minWidth: "350px", // Set width of the toast
//             fontSize: "10px",  // Set a bigger font size
//           },
//         }
//       );
//     } catch (error) {
//       console.error("Failed to switch network:", error);
//       toast(
//         (t) => (
//           <div className="p-4 w-full text-xl">
//             <span>Failed to switch network. Please try again.</span>
//             <button
//               onClick={() => toast.dismiss(t.id)}
//               className="ml-4 bg-red-500 text-white px-3 py-1 rounded-lg"
//             >
//               X
//             </button>
//           </div>
//         ),
//         {
//           duration: Infinity,
//           position: "top-right",
//           style: {
//             minWidth: "350px",
//             fontSize: "18px",
//           },
//         }
//       );
//     }
//   };

//   if (userId && isSetupProfilePage) {
//     return null;
//   }

//   return (
//     <header
//       className={classNames(
//         `mt-1 sticky-header-custom px-4 py-4 flex flex-row items-center justify-between gap-4 sticky top-0 z-20 ${
//           isCampaignPage ? "bg-monochrome-60" : "bg-monochrome-60"
//         }`,
//         { hidden: !userId }
//       )}
//     >
//       <h1 className="mb-0">
//         {NavBarList.find((item: any) => item.link === pathname)?.title}
//         {isMarketplaceDetailsPage && (
//           <div className="flex items-center" style={{ gap: "16px" }}>
//             <Button
//               rounded
//               classNames="bg-monochrome-100 rounded-lg aspect-square !p-3"
//               onClick={() => window.history.back()}
//             >
//               <svg
//                 width="17"
//                 height="17"
//                 viewBox="0 0 17 17"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   fillRule="evenodd"
//                   clipRule="evenodd"
//                   d="M10.8855 3.94281L5.27606 9.55225L4.33325 8.60945L9.94272 3L10.8855 3.94281Z"
//                   fill="white"
//                 />
//                 <path
//                   opacity="0.4"
//                   fillRule="evenodd"
//                   clipRule="evenodd"
//                   d="M9.94272 14.219L4.33325 8.60955L5.27606 7.66675L10.8855 13.2762L9.94272 14.219Z"
//                   fill="white"
//                 />
//               </svg>
//             </Button>
//             Go back
//           </div>
//         )}
//       </h1>

//       <div className="flex flex-row gap-x-2 items-center sm:gap-y-4">
//         <div className="flex items-center gap-x-6 pr-2">
//           <Dropdown
//             icon={
//               <img
//                 src={
//                   selectedNetwork === "polygon"
//                     ? networkOptions[0].icon
//                     : networkOptions[1].icon
//                 }
//                 alt="network icon"
//               />
//             }
//             value={selectedNetwork}
//             className="w-full md:w-[200px] h-[38px] bg-erieblack rounded"
//             onChange={(selectedValue: any) => handleNetworkChange(selectedValue.value)}
//             options={networkOptions}
//           />
//           <Notifications />
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Header;

import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast"; // Import react-hot-toast
import { ROUTES } from "@/constants";
import { NavBarList } from "@/constants/NavMenu";
import useAuth from "@/hooks/useAuth";
import { useNetwork } from "@/provider/NetworkContext";
import { classNames } from "@/utils";
import "./Header.css";
import Notifications from "./Notifications";
import Button from "./UI/Button";
import Dropdown from "./UI/Dropdown/Dropdown";
import { AuthContext } from "@/provider/AuthContext";
import { IcPeaqLogo } from "@/assets";
import IcPolygonLogo from "@/assets/ic_polygon_logo";
import NotificationCard from "./NotificationsCard";

const networkOptions = [
  {
    value: "polygon",
    label: "Polygon",
    icon: <IcPolygonLogo />,
  },
  {
    value: "peaq",
    label: "PEAQ",
    icon: <IcPeaqLogo />,
  },

  // {
  //   value: "ethereum",
  //   label: "Ethereum",
  //   icon: "./assets/ic_ethereum.svg",
  // },
];

const Header: React.FC = () => {
  const { pathname } = useLocation();
  const { userId } = useAuth();
  const { selectedNetwork, setSelectedNetwork } = useNetwork();
  const { switchNetwork } = useContext(AuthContext);
  const [pendingNetwork, setPendingNetwork] = useState<string>("");
  const [showNotificationCard, setShowNotificationCard] = useState(false);
  const isSetupProfilePage = pathname === ROUTES.PROFILE_SETUP;
  const isMarketplaceDetailsPage = pathname === ROUTES.MARKETPLACEDETAILS;
  const isCampaignPage = pathname === ROUTES.CAMPAIGN;

  const handleNetworkChange = async (network: string) => {
    setPendingNetwork(network);
    try {
      await switchNetwork(); // Switching the network
      setSelectedNetwork(network); // Set the new selected network

      // Show toast with "Infinity" duration (stays until the user clicks "X")
      // toast.success(`Network switched to ${network} successfully!`, {
      //   // duration: Infinity, // Make the toast persistent
      //   duration: 3000,
      //   position: "top-right", // You can customize the position
      // });
    } catch (error) {
      console.error("Failed to switch network:", error);
      toast.error("Failed to switch network. Please try again.", {
        duration: Infinity, // Persistent error toast
        position: "top-right",
      });
    }
  };

  if (userId && isSetupProfilePage) {
    return null;
  }

  const handleNotificationClick = () => {
    setShowNotificationCard((prev) => !prev);
  };

  return (
    <>
      <header
        className={classNames(
          `mt-1 sticky-header-custom px-4 py-4 flex flex-row items-center justify-between gap-4 sticky top-0 z-20 ${
            isCampaignPage ? "bg-monochrome-60" : "bg-monochrome-60"
          }`,
          { hidden: !userId }
        )}
      >
        <h1 className="mb-0">
          {NavBarList.find((item: any) => item.link === pathname)?.title}
          {isMarketplaceDetailsPage && (
            <div className="flex items-center gap-4">
              <Button rounded classNames="bg-monochrome-100 rounded-xl aspect-square !p-2" onClick={() => window.history.back()}>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M10.8855 3.94281L5.27606 9.55225L4.33325 8.60945L9.94272 3L10.8855 3.94281Z" fill="white" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.94272 14.2193L4.33325 8.60979L5.27606 7.66699L10.8855 13.2765L9.94272 14.2193Z"
                    fill="white"
                  />
                </svg>
              </Button>
              <p className="text-[12px] font-normal">Go back</p>
            </div>
          )}
        </h1>

        <div className="flex flex-row gap-x-2 items-center sm:gap-y-4">
          <div className="flex items-center gap-x-6">
            <Dropdown
              // icon={<img src={selectedNetwork === "polygon" ? networkOptions[1].icon : networkOptions[0].icon} alt="network icon" />}
              icon={selectedNetwork === "polygon" ? networkOptions[0].icon : networkOptions[1].icon}
              value={selectedNetwork}
              className="w-full md:w-[160px] h-[38px] bg-erieblack rounded"
              onChange={(selectedValue: any) => handleNetworkChange(selectedValue.value)}
              options={networkOptions}
            />
            {/* <Notifications onNotificationClick={handleNotificationClick} /> */}
          </div>
        </div>
      </header>
      {/* {showNotificationCard &&  */}

      <NotificationCard onClose={() => setShowNotificationCard(false)} />
      {/* } */}
    </>
  );
};

export default Header;
