import React from "react";

const FillStaking = (props: any) => {
  return (
    <div {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4885_791301)">
          <path
            d="M12.8444 7.75488C11.2929 7.75488 9.80496 8.37122 8.70787 9.46831C7.61078 10.5654 6.99445 12.0534 6.99445 13.6049C6.99445 15.1564 7.61078 16.6444 8.70787 17.7415C9.80496 18.8385 11.2929 19.4549 12.8444 19.4549C14.396 19.4549 15.8839 18.8385 16.981 17.7415C18.0781 16.6444 18.6944 15.1564 18.6944 13.6049C18.6944 12.0534 18.0781 10.5654 16.981 9.46831C15.8839 8.37122 14.396 7.75488 12.8444 7.75488Z"
            stroke="#00CC9C"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.4945 3.25508C11.4945 4.24958 9.07706 5.05508 6.09446 5.05508C3.11186 5.05508 0.694458 4.24958 0.694458 3.25508C0.694458 2.26058 3.11186 1.45508 6.09446 1.45508C9.07706 1.45508 11.4945 2.26058 11.4945 3.25508Z"
            stroke="#00CC9C"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.49 3.25996C11.49 4.25446 9.0726 5.05996 6.09 5.05996C3.1074 5.05996 0.690002 4.25446 0.690002 3.25996C0.690002 2.26546 3.1074 1.45996 6.09 1.45996C9.0726 1.45996 11.49 2.26546 11.49 3.25996Z"
            fill="#00CC9C"
          />
          <path
            d="M0.694458 3.25488V10.4936C0.694458 11.1272 1.75466 11.9246 4.41146 12.2549M0.790758 7.34808C1.87526 8.30388 3.80846 8.70888 5.87306 8.70888M11.4837 3.36378V5.17728"
            stroke="#00CC9C"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13 19.5996C16.3137 19.5996 19 16.9133 19 13.5996C19 10.2859 16.3137 7.59961 13 7.59961C9.68629 7.59961 7 10.2859 7 13.5996C7 16.9133 9.68629 19.5996 13 19.5996ZM12.9394 17.0421C13.0401 17.0421 13.1333 16.9952 13.3162 16.9033L13.3292 16.8967L14.8647 16.1152L14.8702 16.1125L14.8702 16.1125C15.5469 15.7687 15.8848 15.5971 15.8848 15.3239V11.8875C15.8848 11.6143 15.5469 11.4427 14.8702 11.0989L14.8647 11.0962L13.3292 10.3146L13.3162 10.3081C13.1334 10.2162 13.0401 10.1693 12.9394 10.1693C12.8387 10.1693 12.7454 10.2162 12.5626 10.3081L12.5496 10.3146L11.014 11.0962C10.3336 11.4408 9.99394 11.6136 9.99394 11.8875V15.3239C9.99394 15.5971 10.3318 15.7687 11.0086 16.1125L11.014 16.1152L12.5496 16.8967L12.5626 16.9033C12.7454 16.9952 12.8387 17.0421 12.9394 17.0421Z"
            fill="#00CC9C"
          />
          <path
            d="M13 17C13.105 17 13.202 16.95 13.397 16.852L14.961 16.056C15.654 15.704 16 15.529 16 15.25V11.75M13 17C12.895 17 12.798 16.95 12.603 16.852L11.039 16.056C10.346 15.704 10 15.529 10 15.25V11.75M13 17V13.5M16 11.75C16 11.471 15.654 11.296 14.961 10.944L13.397 10.148C13.202 10.05 13.105 10 13 10C12.895 10 12.798 10.05 12.603 10.148L11.039 10.944C10.346 11.295 10 11.471 10 11.75M16 11.75C16 12.029 15.654 12.204 14.961 12.556L13.397 13.352C13.202 13.45 13.105 13.5 13 13.5M10 11.75C10 12.029 10.346 12.204 11.039 12.556L12.603 13.352C12.798 13.45 12.895 13.5 13 13.5"
            stroke="#00CC9C"
            strokeWidth="0.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4885_791301">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default FillStaking;
