const IcTickmark = (props: any) => {
    return (
        <div {...props}>
            <svg viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 16.75L12.993 23L23 10.5" stroke="currentColor" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div>
    )
}

export default IcTickmark