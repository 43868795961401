const IcTransferExchange = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.3624 3L4.54479 5.317C4.14392 5.607 3.94349 5.753 4.01388 5.877C4.08428 6 4.36782 6 4.93491 6H12.7998M9.4376 13L12.2552 10.683C12.6561 10.393 12.8565 10.247 12.7861 10.123C12.7157 10 12.4322 10 11.8651 10H4.00019"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IcTransferExchange;
