const IcWarning = (prop: any) => {
    return (
        <div {...prop}>
            <svg width="46" height="45" viewBox="0 0 46 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" width="45" height="45" rx="22.5" fill="#282C35" />
                <path d="M20.6196 12.4213C21.6539 10.5508 24.3425 10.5508 25.3768 12.4213L34.7366 29.3392C35.7395 31.1517 34.4284 33.375 32.3574 33.375H13.6403C11.568 33.375 10.2569 31.1517 11.2599 29.3392L20.6196 12.4213ZM24.2047 28.5417C24.2099 28.38 24.1824 28.219 24.1241 28.0681C24.0658 27.9173 23.9777 27.7797 23.8652 27.6635C23.7526 27.5474 23.6179 27.455 23.469 27.3919C23.32 27.3289 23.1599 27.2964 22.9982 27.2964C22.8365 27.2964 22.6764 27.3289 22.5275 27.3919C22.3785 27.455 22.2438 27.5474 22.1313 27.6635C22.0187 27.7797 21.9307 27.9173 21.8723 28.0681C21.814 28.219 21.7866 28.38 21.7917 28.5417C21.8016 28.8551 21.9331 29.1523 22.1583 29.3705C22.3834 29.5886 22.6847 29.7106 22.9982 29.7106C23.3118 29.7106 23.613 29.5886 23.8382 29.3705C24.0634 29.1523 24.1948 28.8551 24.2047 28.5417ZM23.8918 19.0526C23.8605 18.8251 23.7439 18.6179 23.5657 18.473C23.3874 18.3281 23.1608 18.2563 22.9316 18.2721C22.7024 18.2879 22.4878 18.3901 22.3312 18.5581C22.1745 18.7261 22.0875 18.9474 22.0877 19.1771L22.0926 24.6158L22.101 24.7391C22.1323 24.9666 22.2489 25.1738 22.4271 25.3187C22.6054 25.4636 22.832 25.5354 23.0612 25.5196C23.2904 25.5038 23.505 25.4016 23.6616 25.2336C23.8183 25.0656 23.9053 24.8443 23.9051 24.6146L23.9002 19.1747L23.8918 19.0526Z" fill="currentColor" />
            </svg>
        </div>
    )
}

export default IcWarning;