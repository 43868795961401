import React from "react";
import Select, { components } from "react-select";
import { DropdownProps, Option } from "./DropdownProps";

const { Control, Option: DefaultOption } = components;

const StyledDropdown: React.FC<DropdownProps> = ({
  options,
  onChange,
  icon,
  value,
  disabled,
  className,
  height,
  menuBoxMarginTop = 4,
  isSearchable = false,
  isMulti = false,
}) => {
  const handleChange = (selectedOption: Option | Option[] | null) => {
    if (isMulti) {
      onChange(selectedOption as Option[]); 
    } else {
      onChange(selectedOption as Option | null); 
    }
  };

  const CustomValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    const selectedValues = getValue();
  
    let displayLabel = "";
    if (selectedValues.length > 0) {
      displayLabel = `${selectedValues.length} selected`;
    }
  
    return (
      <components.ValueContainer {...props}>
        {displayLabel || children}
      </components.ValueContainer>
    );
  };

  return (
    <Select
      options={options}
      className={`${className} transactions-project-category`}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          border: "none",
          boxShadow: "none",
          borderColor: "transparent",
          height: height,
          background: "#1A1C22",
          borderRadius: "6px",
          color: "white",
          fontSize: "14px",
          width: "300px"
        }),
        menu: (provided) => ({
          ...provided,
          marginTop: menuBoxMarginTop + "px",
          backgroundColor: "#1A1C22",
          borderRadius: "10px",
          border:"1px solid #383838"
        }),
      }}
      value={isMulti ? (value as Option[] | null) : options?.find((option) => option.value === value)}
      components={{
        IndicatorSeparator: () => null,
        Control: ({ children, ...props }) => {
          const selectedOptions = props.getValue();

          return (
            <Control className="flex items-center gap-2 xl:!w-[300px] md:!w-[200px] !w-full p-[3px] !border-2 !bg-[#1A1C22] !border-transparent text-sm select-dropdown transactions-dropdown focus:border-monochrome-40" {...props}>
              {icon}
              {children}
            </Control>
          );
        },
        ValueContainer: CustomValueContainer,
        ClearIndicator: () => null,
        Option: ({ innerProps, label, data, isSelected }) => {
          return (
            <div
              className={`flex items-center justify-between gap-2 h-10 p-4 text-white cursor-pointer hover:bg-monochrome-60`}
              {...innerProps}
            >
              <div className="flex items-center gap-2">
                <div
                  className={`w-4 h-4 border-2 rounded-sm flex items-center justify-center ${
                    isSelected ? 'bg-green-500 border-green-500' : 'border-white'
                  }`}
                >
                  {isSelected && (
                    <span className="text-white">✓</span>
                  )}
                </div>
                <span className="text-sm">{label}</span>
              </div>
            </div>
          );
        },
      }}
      isSearchable={isSearchable}
      onChange={handleChange}
      isDisabled={disabled}
      isMulti={isMulti}
      closeMenuOnSelect={false} 
      hideSelectedOptions={false}
    />
  );
};

export default StyledDropdown;
