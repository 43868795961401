// // // Web3AuthService.ts
// // import { CHAIN_NAMESPACES } from "@web3auth/base";
// // import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
// // import { Web3AuthNoModal } from "@web3auth/no-modal";
// // import { OpenloginAdapter, WhiteLabelData } from "@web3auth/openlogin-adapter";

// // //ui-config
// // const uiConfig: WhiteLabelData = {
// //   appName: "penomo-protocol",
// // }


// // // Mainnet
// // const chainConfig = {
// //   chainNamespace: CHAIN_NAMESPACES.EIP155,
// //   chainId: "0x89",
// //   rpcTarget: process.env.REACT_APP_POLYGON_MAINNET_RPC_URL || "",
// //   displayName: "Polygon PoS",
// //   blockExplorer: "https://polygonscan.com/",
// //   ticker: "POL",
// //   tickerName: "Polygon",
// //   // logo: "https://images.toruswallet.io/eth.svg",
// // };
// // const clientId = process.env.REACT_APP_WEB3AUTH_ID || "";
// // const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });

// // const web3auth = new Web3AuthNoModal({
// //   clientId,
// //   chainConfig,
// //   web3AuthNetwork: "sapphire_mainnet",
// //   uiConfig
// // });

// // const openloginAdapter = new OpenloginAdapter({
// //   privateKeyProvider: privateKeyProvider,
// // });
// // web3auth.configureAdapter(openloginAdapter);

// // export { web3auth };





// // Web3AuthService.ts
// import { CHAIN_NAMESPACES } from "@web3auth/base";
// import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
// import { Web3AuthNoModal } from "@web3auth/no-modal";
// import { OpenloginAdapter, WhiteLabelData } from "@web3auth/openlogin-adapter";

// // UI Config
// const uiConfig: WhiteLabelData = {
//   appName: "penomo-protocol",
// }

// // PEAQ Mainnet Configuration
// const chainConfig = {
//   chainNamespace: CHAIN_NAMESPACES.EIP155,
//   chainId: "0xd0a", 
//   rpcTarget: process.env.REACT_APP_PEAQ_MAINNET_RPC_URL || "",
//   displayName: "PEAQ Mainnet",
//   blockExplorer: "https://peaq.subscan.io/", 
//   ticker: "PEAQ",
//   tickerName: "PEAQ",
// };

// const clientId = process.env.REACT_APP_WEB3AUTH_ID || "";
// const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });

// const web3auth = new Web3AuthNoModal({
//   clientId,
//   chainConfig,
//   web3AuthNetwork: "sapphire_mainnet",
//   uiConfig
// });

// const openloginAdapter = new OpenloginAdapter({
//   privateKeyProvider: privateKeyProvider,
// });
// web3auth.configureAdapter(openloginAdapter);

// export { web3auth };

// // src/services/Web3AuthService.ts

// import { CHAIN_NAMESPACES } from "@web3auth/base";
// import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
// import { Web3AuthNoModal } from "@web3auth/no-modal";
// import { OpenloginAdapter, WhiteLabelData } from "@web3auth/openlogin-adapter";
// import { useNetwork } from "@/provider/NetworkContext";
// import { useMemo } from "react";

// // UI Config
// const uiConfig: WhiteLabelData = {
//   appName: "penomo-protocol",
// };

// // Function to get chain configuration based on network
// const getChainConfig = (network: string) => {
//   switch (network) {
//     case "polygon":
//       return {
//         chainNamespace: CHAIN_NAMESPACES.EIP155,
//         chainId: "0x89", // Polygon Mainnet
//         rpcTarget: process.env.REACT_APP_POLYGON_MAINNET_RPC_URL || "",
//         displayName: "Polygon PoS",
//         blockExplorer: "https://polygonscan.com/",
//         ticker: "POL",
//         tickerName: "Polygon",
//       };
//     case "peaq":
//       return {
//         chainNamespace: CHAIN_NAMESPACES.EIP155,
//         chainId: "0xd0a", // PEAQ Mainnet
//         rpcTarget: process.env.REACT_APP_PEAQ_MAINNET_RPC_URL || "",
//         displayName: "PEAQ Mainnet",
//         blockExplorer: "https://peaq.subscan.io/",
//         ticker: "PEAQ",
//         tickerName: "PEAQ",
//       };
//     default:
//       throw new Error("Unsupported network");
//   }
// };

// // Function to create Web3Auth instance based on network
// const createWeb3AuthInstance = (network: string) => {
//   const chainConfig = getChainConfig(network);
//   const clientId = process.env.REACT_APP_WEB3AUTH_ID || "";
//   const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });

//   const web3authInstance = new Web3AuthNoModal({
//     clientId,
//     chainConfig,
//     web3AuthNetwork: "sapphire_mainnet",
//     uiConfig,
//   });

//   const openloginAdapter = new OpenloginAdapter({
//     privateKeyProvider: privateKeyProvider,
//   });
//   web3authInstance.configureAdapter(openloginAdapter);

//   return web3authInstance;
// };

// // Wrapper to initialize Web3Auth in a component
// const useWeb3Auth = () => {
//   const { selectedNetwork } = useNetwork();

//   // Initialize Web3Auth instance based on selected network
//   const web3auth = useMemo(() => createWeb3AuthInstance(selectedNetwork), [selectedNetwork]);

//   return web3auth;
// };

// // Maintain backward compatibility by exporting `web3auth`
// const defaultNetwork = "polygon"; // Default fallback network if not initialized dynamically
// const web3auth = createWeb3AuthInstance(defaultNetwork);

// export { useWeb3Auth, web3auth };



import { CHAIN_NAMESPACES, CustomChainConfig } from "@web3auth/base";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter, WhiteLabelData } from "@web3auth/openlogin-adapter";
import { useNetwork } from "@/provider/NetworkContext";
import { useMemo, useCallback } from "react";

// UI Config
const uiConfig: WhiteLabelData = {
  appName: "penomo-protocol",
};
// Chain configurations
const chainConfigs: { [key: string]: CustomChainConfig } = {
  polygon: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0x89", // Polygon Mainnet
    rpcTarget: process.env.REACT_APP_POLYGON_MAINNET_RPC_URL || "",
    displayName: "Polygon PoS",
    blockExplorerUrl: "https://polygonscan.com/",
    ticker: "MATIC",
    tickerName: "Polygon",
  },
  peaq: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: "0xd0a", // PEAQ Mainnet
    rpcTarget: process.env.REACT_APP_PEAQ_MAINNET_RPC_URL || "",
    displayName: "PEAQ Mainnet",
    blockExplorerUrl: "https://peaq.subscan.io/",
    ticker: "PEAQ",
    tickerName: "PEAQ",
  },
  ethereum: {
    chainNamespace: CHAIN_NAMESPACES.EIP155,  // EIP-155 is used for Ethereum and Ethereum-like networks
    chainId: "0x1",  // Ethereum Mainnet chain ID
    rpcTarget: process.env.REACT_APP_MAINNET_RPC_URL || "",  // Default to an Ethereum RPC provider (Infura in this case)
    displayName: "Ethereum Mainnet",
    blockExplorerUrl: "https://etherscan.io/",
    ticker: "ETH",  // Ethereum's native currency ticker
    tickerName: "Ether",
  },
};

// Function to create Web3Auth instance
const createWeb3AuthInstance = () => {
  const clientId = process.env.REACT_APP_WEB3AUTH_ID || "";
  const web3authInstance = new Web3AuthNoModal({
    clientId,
    chainConfig: chainConfigs.peaq, // Default to peaq
    web3AuthNetwork: "sapphire_mainnet",
    uiConfig,
  });

  const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig: chainConfigs.peaq } });
  const openloginAdapter = new OpenloginAdapter({
    privateKeyProvider,
  });
  web3authInstance.configureAdapter(openloginAdapter);

  return web3authInstance;
};

// Create a singleton instance
const web3authInstance = createWeb3AuthInstance();

// Hook to use Web3Auth
const useWeb3Auth = () => {
  const { selectedNetwork } = useNetwork();

  const switchNetwork = useCallback(async () => {
    if (!web3authInstance.provider) {
      throw new Error("Provider not initialized");
    }

    const chainConfig = chainConfigs[selectedNetwork];
    if (!chainConfig) {
      throw new Error("Invalid network selected");
    }

    try {
      await web3authInstance.addChain(chainConfig);
      await web3authInstance.switchChain({ chainId: chainConfig.chainId });
    } catch (error) {
      console.error("Error switching chain:", error);
      throw error;
    }
  }, [selectedNetwork]);

  const initializeWeb3Auth = useCallback(async () => {
    if (!web3authInstance.provider) {
      await web3authInstance.init();
    }
    await switchNetwork();
  }, [switchNetwork]);

  return useMemo(() => ({
    web3auth: web3authInstance,
    initializeWeb3Auth,
    switchNetwork,
  }), [initializeWeb3Auth, switchNetwork]);
};

export { useWeb3Auth, web3authInstance };
