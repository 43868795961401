import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { z } from "zod";
import useStore from "@store/useStore";
import { http } from "@services/http";
import { ENDPOINT } from "@constants";
import Button from "./UI/Button";
import Select from "react-select";
import currencyCodes from "currency-codes";
import Dropdown from "./UI/Dropdown/Dropdown";
import { fetchInvestor, updateInvestorBankDetails } from "@/services/api";
import { useQuery } from "@tanstack/react-query";

// Zod schema for form validation
const bankDetailsSchema = z.object({
  bankAccountHolderName: z.string().min(1, "Account holder name is required"),
  bankName: z.string().min(1, "Bank name is required"),
  bankAccountNumber: z.string().min(1, "Account number is required"),
  bankSwiftBicCode: z.string().min(1, "Swift/BIC code is required"),
  bankIban: z.string().min(1, "IBAN is required"),
  bankDefaultCurrency: z.string().min(1, "Default currency is required"),
});

interface errors {
  bankAccountHolderName?: string;
  bankName?: string;
  bankAccountNumber?: string;
  bankSwiftBicCode?: string;
  bankIban?: string;
  bankDefaultCurrency?: string;
}

const currencyOptions = currencyCodes.data.map((currency) => ({
  value: currency.code,
  label: `${currency.code} - ${currency.currency}`,
}));

const BankForm = () => {
  const { userData, investorData } = useStore();

  const { data: investorDataRefetch = null } =
    useQuery({
      queryKey: ["getInvestor", investorData._id],
      queryFn: () => fetchInvestor(investorData._id),
      enabled: !!investorData._id,
    }) || {};

  useEffect(() => {
    setFormData({
      bankAccountHolderName: investorDataRefetch?.data?.bankAccountHolderName ?? "",
      bankName: investorDataRefetch?.data?.bankName ?? "",
      bankAccountNumber: investorDataRefetch?.data?.bankAccountNumber ?? "",
      bankSwiftBicCode: investorDataRefetch?.data?.bankSwiftBicCode ?? "",
      bankIban: investorDataRefetch?.data?.bankIban ?? "",
      bankDefaultCurrency: investorDataRefetch?.data?.bankDefaultCurrency ?? "USD",
    });
  }, [investorDataRefetch?.data]);

  const [formData, setFormData] = useState({
    bankAccountHolderName: investorDataRefetch?.data?.bankAccountHolderName ?? "",
    bankName: investorDataRefetch?.data?.bankName ?? "",
    bankAccountNumber: investorDataRefetch?.data?.bankAccountNumber ?? "",
    bankSwiftBicCode: investorDataRefetch?.data?.bankSwiftBicCode ?? "",
    bankIban: investorDataRefetch?.data?.bankIban ?? "",
    bankDefaultCurrency: investorDataRefetch?.data?.bankDefaultCurrency ?? "USD",
  });

  const [initialFormData, setInitialFormData] = useState<any>({});
  const [errors, setErrors] = useState<errors>({});
  const [editMode, setEditMode] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    validateField(name, value);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    setFormData(initialFormData);
    setErrors({});
  };

  const handleCurrencyChange = (selectedOption) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      bankDefaultCurrency: selectedOption.value,
    }));
    validateField("bankDefaultCurrency", selectedOption.value);
  };

  const validateField = (name: string, value: any) => {
    const result = bankDetailsSchema.pick({ [name]: true }).safeParse({ [name]: value });
    if (!result.success) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: result.error.flatten().fieldErrors[name][0] }));
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!editMode) return;

    const result = bankDetailsSchema.safeParse(formData);
    if (!result.success) {
      setErrors(result.error.flatten().fieldErrors as any);
      toast.error("Please correct the errors before submitting.");
      return;
    }

    try {
      const response = await updateInvestorBankDetails(investorDataRefetch?.data?._id, formData);

      if (response.code === 200) {
        toast.success("Banking details updated successfully!");
        setInitialFormData(formData);
        setEditMode(false);
      } else {
        toast.error("Failed to update banking details.");
      }
    } catch (error) {
      console.error("Error updating banking details:", error);
      toast.error("Failed to update banking details.");
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <h4 className="text-monochrome-20">Bank Details</h4>
      <div className="flex flex-col sm:flex-row bg-monochrome-100 p-4 rounded divide-y sm:divide-y-0 sm:divide-x divide-monochrome-40">
        <div className="flex flex-col gap-2 sm:w-4/12 pb-4  md:pb-0">
          <h6 className="text-[0.875rem]">Bank Details</h6>
          <p className="text-monochrome-20 text-[0.688rem]">Provide your bank details</p>
        </div>
        <form className="flex flex-col body-small-regular gap-6 w-full sm:pl-8 pt-4 lg:pt-0 sm:pr-6 sm:w-8/12" onSubmit={handleSubmit}>
          <div className="grid grid-cols-2 gap-6">
            <div className="relative flex flex-col gap-2">
              <label>Account Holder Name</label>
              <input
                type="text"
                name="bankAccountHolderName"
                placeholder="Enter your company name here"
                disabled={!editMode}
                className={`w-full px-0 ${editMode ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"}`}
                value={formData.bankAccountHolderName}
                onChange={handleChange}
              />
              {errors.bankAccountHolderName && <p className="text-red-500">{errors.bankAccountHolderName}</p>}
            </div>
            <div className="relative flex flex-col gap-2">
              <label>Bank Name</label>
              <input
                type="text"
                name="bankName"
                placeholder="Enter your registration number here"
                disabled={!editMode}
                className={`w-full px-0 ${editMode ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"}`}
                value={formData.bankName}
                onChange={handleChange}
              />
              {errors.bankName && <p className="text-red-500">{errors.bankName}</p>}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div className="relative flex flex-col gap-2">
              <label>Account Number</label>
              <input
                type="text"
                name="bankAccountNumber"
                placeholder="Enter your account number here"
                disabled={!editMode}
                className={`w-full px-0 ${editMode ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"}`}
                value={formData.bankAccountNumber}
                onChange={handleChange}
              />
              {errors.bankAccountNumber && <p className="text-red-500">{errors.bankAccountNumber}</p>}
            </div>
            <div className="relative flex flex-col gap-2">
              <label>SWIFT/BIC Code</label>
              <input
                type="text"
                name="bankSwiftBicCode"
                placeholder="Enter your SWIFT/BIC code here"
                disabled={!editMode}
                className={`w-full px-0 ${editMode ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"}`}
                value={formData.bankSwiftBicCode}
                onChange={handleChange}
              />
              {errors.bankSwiftBicCode && <p className="text-red-500">{errors.bankSwiftBicCode}</p>}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6">
            <div className="relative flex flex-col gap-2">
              <label>IBAN</label>
              <input
                type="text"
                name="bankIban"
                placeholder="Enter your IBAN here"
                disabled={!editMode}
                className={`w-full px-0 ${editMode ? "border border-monochrome-10 rounded px-2" : "border-none !rounded-none"}`}
                value={formData.bankIban}
                onChange={handleChange}
              />
              {errors.bankIban && <p className="text-red-500">{errors.bankIban}</p>}
            </div>
            <div className="relative flex flex-col gap-2">
              <label>Default Currency</label>
              <Dropdown
                options={currencyOptions}
                isSearchable
                value={currencyOptions.find((option) => option.value == formData.bankDefaultCurrency)?.value}
                onChange={handleCurrencyChange}
                disabled={!editMode}
                className={`w-full ${editMode ? "rounded" : "border-none !rounded-none"}`}
              />
              {errors.bankDefaultCurrency && <p className="text-red-500">{errors.bankDefaultCurrency}</p>}
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-4">
            {editMode ? (
              <>
                <Button rounded classNames="bg-monochrome-60 text-white text-xs" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button secondary rounded classNames="h-10" type="submit" isLoading={isSubmittingForm}>
                  Save
                </Button>
              </>
            ) : (
              <Button primary rounded classNames="h-10" onClick={handleEdit}>
                Edit
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default BankForm;
