import React from "react";
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { IPortfolioHoldings } from "@/types";

import { IcSwap, IcArrowUpSort, IcArrowDownSort } from "@/assets";
import { classNames } from "@/utils";
import { useNavigate } from "react-router-dom";
import Loader from "@/components/Loader";

interface IPortfolioHoldingsProps {
  data: IPortfolioHoldings[];
  isLoading: boolean;
}

const getAsset = (asset: string): string => {
  const enums: Record<AssetType, string> = {
    BATTERY: "/assets/dummy/battery.jpg",
    SOLAR: "/assets/dummy/solar.jpg",
    WIND: "/assets/dummy/wind.jpg",
    EVCHARGING: "/assets/dummy/ev.jpg",
  };
  // Type narrowing
  const isValidAssetType = (type: string): type is AssetType => {
    return ["BATTERY", "SOLAR", "WIND", "EVCHARGING"].includes(type);
  };
  const imageAsset = isValidAssetType(asset) ? enums[asset] : enums["BATTERY"];
  return imageAsset;
};

const formatWeiToEther = (weiValue: string): number => {
  const etherValue = +weiValue / Math.pow(10, 18); // Divide by 10^18 to convert wei to ether
  return etherValue; // Format to 4 decimal places (adjust as needed)
};

const renderProfit = (data: IPortfolioHoldings) => {
  // RANDOMIZE FOR NOW
  const range = Array.from({ length: 21 }, (_, index) => index - 10);
  const randomized = range[Math.floor(Math.random() * range.length)];
  const profit = (formatWeiToEther(data.tokenBalance) * 500 * randomized) / 100;

  return (
    <p className={`text-monochrome-20 font-medium ${profit > 0 ? "text-primary-100" : profit < 0 ? "!text-danger-100" : ""}`}>
      {profit > 0 ? "+" : profit < 0 ? "-" : ""}${Math.abs(profit).toLocaleString()}
    </p>
  );
};

const getTokenAmount = (data: IPortfolioHoldings): number => {
  return formatWeiToEther(data?.tokenBalance);
};

const renderToken = (data: IPortfolioHoldings) => {
  const asset = data.projectId?.assetIds ? (data.projectId.assetIds[0]?.assetType as string) : "";
  const imageAsset = getAsset(asset);
  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center p-[16px]">
        <img src={data.projectId?.projectImage || imageAsset} className="w-[40px] h-[40px] rounded-[8px] mr-[16px]" alt="token" />
        <div>
          <p className="text-white font-medium text-xs">{data?.projectName}</p>
          <p className="text-monochrome-20 text-xs">{data?.tokenSymbol}</p>
        </div>
      </div>
    </div>
  );
};

const renderSortIcon = (column: any) => {
  return (
    <div className=" ml-[4px]">
      {{
        asc: <IcArrowUpSort />,
        desc: <IcArrowDownSort />,
      }[column.getIsSorted() as string] ?? <IcSwap />}
    </div>
  );
};

const PortofolioHoldings = ({ data, isLoading }: IPortfolioHoldingsProps) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const navigate = useNavigate();

  const columns = React.useMemo<ColumnDef<IPortfolioHoldings>[]>(
    () => [
      {
        accessorKey: "token",
        header: ({ column }) => <div className="px-[16px] py-[10px] flex items-center">Token {renderSortIcon(column)}</div>,
        cell: (info) => renderToken(info.row.original),
        size: 240,
      },
      {
        accessorKey: "total_value",
        header: ({ column }) => <div className="px-[16px] py-[10px] flex items-center justify-end">Total value {renderSortIcon(column)}</div>,
        cell: (info) => <div className="px-[16px] py-[10px] text-right">${(getTokenAmount(info.row.original) * 500).toLocaleString()}</div>,
      },
      {
        accessorKey: "token_amount",
        header: ({ column }) => <div className="px-[16px] py-[10px] flex items-center justify-end">Token amount {renderSortIcon(column)}</div>,
        cell: (info) => <div className="px-[16px] py-[10px] text-right">{getTokenAmount(info.row.original)} tokens</div>,
      },
      {
        accessorKey: "token_price",
        header: ({ column }) => <div className="px-[16px] py-[10px] flex items-center justify-end">Token price {renderSortIcon(column)}</div>,
        cell: (info) => <div className="px-[16px] py-[10px] text-right">$500</div>,
      },
      {
        accessorKey: "apy",
        header: ({ column }) => <div className="px-[16px] py-[10px] flex items-center justify-center">APY {renderSortIcon(column)}</div>,
        cell: (info) => <div className="px-[16px] py-[10px] text-center">10%</div>,
      },
      // {
      //   accessorKey: "profit",
      //   header: ({ column }) => <div className="px-[16px] py-[10px] flex items-center justify-end">Profit {renderSortIcon(column)}</div>,
      //   cell: (info) => <div className="flex items-center justify-end px-[16px] py-[10px]">{renderProfit(info.row.original)}</div>,
      // },
    ],
    []
  );

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  if (isLoading) {
    return(
      <div className="text-center mt-4">
        <p className="flex items-center justify-center min-h-screen">
        <Loader isLoading={true} />;
        </p>
      </div>
    ) 
  }

  if (data.length === 0) {
    return (
      <div className="text-center mt-4">
        <p className="flex items-center justify-center min-h-screen">No Token Holdings.</p>
      </div>
    );
  }

  return (
    <div className=" overflow-x-auto">
      <table className=" w-full mb-[16px] table-fixed">
        <thead className=" border-b-[0.5px] border-monochrome-40">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={classNames(header.column.columnDef.meta?.className, " font-normal text-[11px] text-monochrome-20 text-left")}
                    style={{ width: header.column.getSize() }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        className={header.column.getCanSort() ? "cursor-pointer select-none" : ""}
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                          header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === "asc"
                              ? "Sort ascending"
                              : header.column.getNextSortingOrder() === "desc"
                              ? "Sort descending"
                              : "Clear sort"
                            : undefined
                        }
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                      </div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table
            .getRowModel()
            .rows.slice(0, 10)
            .map((row) => {
              return (
                <tr
                  key={row.id}
                  className=" border-b-[0.5px] border-monochrome-40 text-xs font-normal cursor-pointer"
                  onClick={() => window.open(`https://amoy.polygonscan.com/address/${row.original?.tokenContract}`, "_blank")}
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className={classNames(cell.column.columnDef.meta?.className)} style={{ width: cell.column.getSize() }}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default PortofolioHoldings;
