import React from "react";
import Web3 from "web3";
import { useNetwork } from "@/provider/NetworkContext";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import toast from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";
import { IcMergeCopy } from "@/assets";
import IcSell from "@/assets/ic_sell";
import IcBuy from "@/assets/ic_buy";
import IcCross from "@/assets/ic_cross";
import { IcDropdownChecked } from "@/assets/menu";
import IcPending from "@/assets/ic_pending";
import { formatNumber } from "@/utils";

dayjs.extend(customParseFormat);

const TransactionDetailSideView = ({ transaction, onClose, currentUserId, currentWalletAddress }: any) => {
  // console.log("🚀 ~ TransactionDetailSideView ~ transaction:", transaction);
  const { selectedNetwork } = useNetwork();

  const isTokenTransaction = transaction.transactionType === "token transaction";

  const explorer = isTokenTransaction
    ? `${process.env.REACT_APP_POLYGONSCAN_TRANSACTION_URL}${transaction.hash}`
    : selectedNetwork === "polygon"
    ? `${process.env.REACT_APP_POLYGONSCAN_TRANSACTION_URL}${transaction.transactionHash}`
    : `${process.env.REACT_APP_PEAQ_SUBSCAN_TRANSACTION_URL}${transaction.transactionHash}`;

  const showCopiedToast = (message: string) => {
    toast.success(message);
  };

  const parsedDate = isTokenTransaction ? dayjs(transaction.createdAt) : dayjs(transaction.updatedAt);

  const tokenAmountFormatted = isTokenTransaction
    ? Web3.utils.fromWei(transaction.tokenAmount, "ether")
    : Web3.utils.fromWei(Web3.utils.toWei(transaction.paymentTokensTransferred?.toString(), "ether"), "ether");

  const isOnChain = isTokenTransaction || transaction.transactionMethod === "ON_CHAIN";

  const isIncomingTransaction = () => {
    if (isTokenTransaction) {
      return transaction.to?.toLowerCase() === currentWalletAddress.toLowerCase();
    }
    if (isOnChain) {
      return transaction.toAddress?.toLowerCase() === currentWalletAddress.toLowerCase();
    } else {
      return transaction.toUserId?._id === currentUserId;
    }
  };

  const isOutgoingTransaction = () => {
    if (isTokenTransaction) {
      return transaction.from?.toLowerCase() === currentWalletAddress.toLowerCase();
    }
    if (isOnChain) {
      return transaction.fromAddress?.toLowerCase() === currentWalletAddress.toLowerCase();
    } else {
      return transaction.fromUserId?._id === currentUserId;
    }
  };

  const transactionType = isIncomingTransaction() ? "Buy" : "Sell";
  const transactionColor = isIncomingTransaction() ? "text-green-500" : isOutgoingTransaction() ? "text-red-600" : "text-gray-400";
  const transactionSign = isIncomingTransaction() ? "+" : isOutgoingTransaction() ? "-" : "";

  let statusIcon = isTokenTransaction ? <IcDropdownChecked className="h-[24px] w-[24px] !text-green-500" /> : null;
  let statusTextColor = isTokenTransaction ? "text-green-500" : "";

  if (!isTokenTransaction) {
    switch (transaction.status) {
      case "FAILED":
        statusIcon = <IcCross className="!text-red-500 transform scale-125 p-2" />;
        statusTextColor = "text-red-500";
        break;
      case "COMPLETED":
        statusIcon = <IcDropdownChecked className="h-[24px] w-[24px] !text-green-500" />;
        statusTextColor = "text-green-500";
        break;
      case "PENDING":
        statusIcon = <IcPending className="!text-yellow-300 transform scale-125 p-2" />;
        statusTextColor = "text-yellow-300";
        break;
    }
  }

  let transactionName = isTokenTransaction
    ? "Security Token Transfer"
    : (() => {
        switch (transaction.transactionPurpose) {
          case "SECURITY_TOKEN_PURCHASE":
            return "Security Token Purchase";
          case "DIVIDEND_PAYMENT":
            return "Dividend Payment";
          case "SECURITY_TOKEN_SALE":
            return "Sell Token";
          case "SECURITY_TOKEN_TRANSFER":
            return "Security Token Transfer";
          case "PAYMENT_TOKEN_TOP_UP":
            return "Top Up";
          case "PAYMENT_TOKEN_WITHDRAW":
            return "Withdraw";
          default:
            return "Unknown Transaction";
        }
      })();

  const renderBankDetails = (transaction: any) => {
    const fromUserBankDetails = isOutgoingTransaction() ? transaction.fromUserId?.investorId : transaction.fromUserId?.companyId;

    const toUserBankDetails = isOutgoingTransaction() ? transaction.toUserId?.companyId : transaction.toUserId?.investorId;

    if (transaction.transactionMethod === "OFF_CHAIN") {
      return (
        <div className="flex gap-2 flex-col text-sm space-y-2">
          <span className="text-monochrome-20">
            <p>Account Holder Name:</p>
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {fromUserBankDetails?.bankAccountHolderName || "Not Available"}
            </p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {toUserBankDetails?.bankAccountHolderName || "Not Available"}
            </p>
          </span>
          <span className="text-monochrome-20">
            Bank Name:
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">{fromUserBankDetails?.bankName || "Not available"}</p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">{toUserBankDetails?.bankName || "Not available"}</p>
          </span>
          <span className="text-monochrome-20">
            Account Number:
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {fromUserBankDetails?.bankAccountNumber || "Not available"}
            </p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">{toUserBankDetails?.bankAccountNumber || "Not available"}</p>
          </span>
          <span className="text-monochrome-20">
            SWIFT/BIC Code:
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {fromUserBankDetails?.bankSwiftBicCode || "Not available"}
            </p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">{toUserBankDetails?.bankSwiftBicCode || "Not available"}</p>
          </span>
          <span className="text-monochrome-20">
            IBAN:
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">{fromUserBankDetails?.bankIban || "Not available"}</p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">{toUserBankDetails?.bankIban || "Not available"}</p>
          </span>
          <span className="text-monochrome-20">
            Default Currency:
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {fromUserBankDetails?.bankDefaultCurrency || "Not available"}
            </p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
              {toUserBankDetails?.bankDefaultCurrency || "Not available"}
            </p>
          </span>
          <span className="text-monochrome-20">
            Company Name:
            <p>From:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">{fromUserBankDetails?.companyName || "Not available"}</p>
            <p>To:</p>
            <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">{toUserBankDetails?.companyName || "Not available"}</p>
          </span>
        </div>
      );
    }
    return null;
  };

  const renderTransactionDetails = () => {
    if (isTokenTransaction) {
      return (
        <div className="flex gap-5 flex-col text-sm">
          <div className="flex gap-2 flex-col text-sm">
            <span className="text-monochrome-20">Transaction hash</span>
            <div className="flex flex-row gap-2 items-center">
              <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
                {transaction.hash.length > 10 ? `${transaction.hash.slice(0, 25)}...` : transaction.hash}{" "}
              </p>
              <CopyToClipboard text={transaction.hash} onCopy={() => showCopiedToast("Transaction hash copied to clipboard!")}>
                <IcMergeCopy className={"cursor-pointer"} />
              </CopyToClipboard>
            </div>
          </div>
          <div className="flex gap-2 flex-col text-sm">
            <span className="text-monochrome-20">From</span>
            <div className="flex flex-row gap-2 items-center">
              <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
                {transaction.from.length > 10 ? `${transaction.from.slice(0, 25)}...` : transaction.from}{" "}
              </p>
              <CopyToClipboard text={transaction.from} onCopy={() => showCopiedToast("From address copied to clipboard!")}>
                <IcMergeCopy className={"cursor-pointer"} />
              </CopyToClipboard>
            </div>
          </div>
          <div className="flex gap-2 flex-col text-sm">
            <span className="text-monochrome-20">To</span>
            <div className="flex flex-row gap-2 items-center">
              <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
                {transaction.to.length > 10 ? `${transaction.to.slice(0, 25)}...` : transaction.to}
              </p>
              <CopyToClipboard text={transaction.to} onCopy={() => showCopiedToast("To address copied to clipboard!")}>
                <IcMergeCopy className={"cursor-pointer"} />
              </CopyToClipboard>
            </div>
          </div>
          <div className="flex gap-2 flex-col text-sm">
            <span className="text-monochrome-20">Block Explorer</span>
            <div className="flex flex-row gap-2 items-center">
              <a
                href={`${explorer}`}
                target="_blank"
                rel="noreferrer"
                className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100"
              >{`${explorer}`}</a>
            </div>
          </div>
        </div>
      );
    }

    switch (transaction.transactionMethod) {
      case "OFF_CHAIN":
        switch (transaction.transactionPurpose) {
          case "SECURITY_TOKEN_TRANSFER":
          case "SECURITY_TOKEN_PURCHASE":
            return (
              <>
                <div className="flex gap-2 flex-col text-sm">
                  {renderBankDetails(transaction)}
                  <span className="text-monochrome-20">
                    Security Tokens:{" "}
                    <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">{transaction.securityTokensTransferred}</p>
                  </span>
                </div>
              </>
            );
          case "DIVIDEND_PAYMENT":
            return (
              <>
                <div className="flex gap-2 flex-col text-sm">{renderBankDetails(transaction)}</div>
              </>
            );
          default:
            return <span>Unsupported transaction type.</span>;
        }

      case "ON_CHAIN":
        switch (transaction.transactionPurpose) {
          case "PAYMENT_TOKEN_TOP_UP":
          case "PAYMENT_TOKEN_WITHDRAW":
            return (
              <div className="flex gap-5 flex-col text-sm">
                <div className="flex gap-2 flex-col text-sm">
                  <span className="text-monochrome-20">Transaction hash</span>
                  <div className="flex flex-row gap-2 items-center">
                    <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
                      {transaction.transactionHash.length > 10 ? `${transaction.transactionHash.slice(0, 25)}...` : transaction.transactionHash}{" "}
                    </p>
                    <CopyToClipboard text={transaction.transactionHash} onCopy={() => showCopiedToast("Transaction hash copied to clipboard!")}>
                      <IcMergeCopy className={"cursor-pointer"} />
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="flex gap-2 flex-col text-sm">
                  <span className="text-monochrome-20">From</span>
                  <div className="flex flex-row gap-2 items-center">
                    <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
                      {transaction.fromAddress.length > 10 ? `${transaction.fromAddress.slice(0, 25)}...` : transaction.fromAddress}{" "}
                    </p>
                    <CopyToClipboard text={transaction.fromAddress} onCopy={() => showCopiedToast("From address copied to clipboard!")}>
                      <IcMergeCopy className={"cursor-pointer"} />
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="flex gap-2 flex-col text-sm">
                  <span className="text-monochrome-20">To</span>
                  <div className="flex flex-row gap-2 items-center">
                    <p className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100">
                      {transaction.toAddress.length > 10 ? `${transaction.toAddress.slice(0, 25)}...` : transaction.toAddress}
                    </p>
                    <CopyToClipboard text={transaction.toAddress} onCopy={() => showCopiedToast("To address copied to clipboard!")}>
                      <IcMergeCopy className={"cursor-pointer"} />
                    </CopyToClipboard>
                  </div>
                </div>
                <div className="flex gap-2 flex-col text-sm">
                  <span className="text-monochrome-20">Block Explorer</span>
                  <div className="flex flex-row gap-2 items-center">
                    <a
                      href={`${explorer}`}
                      target="_blank"
                      rel="noreferrer"
                      className="text-ellipsis overflow-hidden whitespace-nowrap flex-grow text-blue-100"
                    >{`${explorer}`}</a>
                  </div>
                </div>
              </div>
            );
          default:
            return <span>Unsupported transaction type.</span>;
        }

      default:
        return <span>Transaction method not recognized.</span>;
    }
  };
  // console.log("Selected Transaction: ",transaction)

  return (
    <div className="z-50 bg-monochrome-100 flex flex-col p-4 gap-8 fixed right-0 top-0 w-full max-w-full lg:max-w-[280px] h-screen overflow-y-auto">
      <div className="flex justify-between items-center">
        <h3>Transaction Details</h3>
        <IcCross className={"p-1 bg-monochrome-60 border border-monochrome-40 rounded-lg cursor-pointer"} onClick={onClose} />
      </div>
      <div className="flex gap-2 flex-col text-sm">
        <span className={`flex items-center`}>
          {isIncomingTransaction() ? <IcBuy className={`mr-2 ${transactionColor}`} /> : <IcSell className={`mr-2 ${transactionColor}`} />}
          <span>{transactionName}</span>
        </span>
        <h1 className={`font-bold ${transactionColor} text-3xl`}>
          {transaction.transactionPurpose == "SECURITY_TOKEN_TRANSFER"
            ? formatNumber(Math.floor(transaction.paymentTokensTransferred)) + " " + transaction.tokenSymbol
            : transaction.transactionType === 'token transaction'
              ? formatNumber(Math.floor(Number(tokenAmountFormatted))) + " " + (isTokenTransaction
                ? transaction.tokenSymbol
                : transaction?.paymentTokenName || "USD")
              : transactionSign + "$" + formatNumber(Math.abs(Number(tokenAmountFormatted)).toFixed(2)) + " " + (isTokenTransaction
                ? transaction.tokenSymbol
                : transaction?.paymentTokenName || "USD")}
        </h1>
      </div>

      <div className={`flex flex-col gap-2 text-sm ${statusTextColor}`}>
        <span className="text-monochrome-20">Status</span>
        <div className="flex items-center">
          {statusIcon}
          <span className={`capitalize ${statusTextColor}`}>{isTokenTransaction ? "completed" : transaction.status.toLowerCase()}</span>
        </div>
      </div>
      <div className="flex gap-2 flex-col text-sm">
        <span className="text-monochrome-20">Time</span>
        <span>{parsedDate.format("hh:mm:ss, MMM DD, YYYY")}</span>
      </div>
      <div className="flex gap-2 flex-col text-sm">
        <span className="text-monochrome-20">Symbol</span>
        <span>{isTokenTransaction ? transaction.tokenSymbol : transaction.securityTokenName}</span>
      </div>
      {renderTransactionDetails()}
    </div>
  );
};

export default TransactionDetailSideView;
